import React, { useContext } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import FooterLogo from "../../Images/footer-logo.png";
import FooterLogolight from "../../Images/ftlight.webp";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import { ThemeContext } from '../DarkMode/ThemeProvider';
import InstagramIcon from '@mui/icons-material/Instagram';
import { FaDiscord } from "react-icons/fa";

const Footer = () => {
  const { isLightMode, handleDarkmode } = useContext(ThemeContext);

  return (
    <div className="footer-full">
      <div className="contain-width">
        <div className="footer-logo">
          <img src={isLightMode ? FooterLogolight : FooterLogo} alt="Logo" />
          <div className="socail-media display-1" style={{ gap: "25px" }}>
            <p>Follow Us</p>

            <Link to="https://www.facebook.com/clicksandtrades">
              <FacebookOutlinedIcon />
            </Link>
            <Link to="https://t.me/clicksandtrades" target="_blank">
              <TelegramIcon />
            </Link>
            <Link to="https://www.instagram.com/clicksandtrades/" target="_blank">
              <InstagramIcon />
            </Link>
            {/* <Link to="">
              <FaDiscord />
            </Link> */}

          </div>
        </div>

        <div className="footer-content display-3">

          <p>Clicks & trade © 2024 All rights reserved</p>
          {/* <div>
            <Link to="">Terms & Policies</Link>
          </div> */}
        </div>

      </div>
    </div>
  );
};

export default Footer;
