import React, { useEffect, useState, useRef } from "react";
import Header from "../Header/Header";
import "./Market.css";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import BTC from "../../Images/coin-1.svg";
import Drawer from '@mui/material/Drawer';
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import star from "../../Images/star.webp";
import { useNavigate } from 'react-router-dom';
import Green from "../../Images/green-chart-1.svg";
import Red from "../../Images/red-chart-1.svg";
import vector from "../../Images/Vector.png";
import Modal from '@mui/material/Modal';
import { Button, Grid2 } from "@mui/material";
import btc from "../../Images/btc.webp";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import bnc from '../../Images/bnc.png';
import okx from '../../Images/okx.png';
import bybit from '../../Images/bybit.jpg';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Axios from "../Axios";
import $ from 'jquery'
import Pagination from '@mui/material/Pagination';
import toast from "react-hot-toast";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import favicon from '../../Images/favicon.png';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import addNotification from "react-push-notification";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Slide } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Support1 from "../../Images/support-1.svg";
import Support2 from "../../Images/support-2.svg";
import Support3 from "../../Images/support-3.svg";
import Support4 from "../../Images/support-4.svg";
import Support5 from "../../Images/support-5.svg";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height: "600px",
  overflowY: "scroll",
  background: 'var(--clr-bg2) !important',
  // border: '2px solid #000',
  // boxShadow: 24,
  borderRadius: '12px',
  border: '1px solid #14ddb6',
  transition: 'all 0.3s ease',
  p: 4,
  
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "400px",
  height: "400px",
  overflowY: "scroll",
  background: 'var(--clr-bg2)  !important',
  // border: '2px solid #000',
  // boxShadow: 24,
  borderRadius: '12px',
  border: '1px solid #14ddb6',
  p: 4,
};
const ws = new WebSocket("wss://stream.binance.com:9443/ws");

const Market = () => {
  const navigate = useNavigate()
  const [openLoad, setOpenLoad] = useState(true);

  // const apiCall = {
  //   "method": "SUBSCRIBE",
  //   "params": [
  //     "btcusdt@ticker",
  //     "bnbusdt@ticker",
  //     "ethusdt@ticker"
  //   ],
  //   "id": 1
  // }

  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 4,
    // slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    // autoplaySpeed: 300,
    // cssEase: "linear"
  };
  AOS.init();
  const [datatusd, setDatatusd] = useState({})
  const [databnb, setDatabnb] = useState([])
  const [datausdc, setDatausdc] = useState([])
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [changePage, setChangePage] = useState(false)
  const [search, setSearch] = useState()
  const [coin, setCoin] = useState('USDT')
  const [exchange, setExchange] = useState('binance')
  const [basevalue, setBasevalue] = useState("")
  const [hrhigh, setHrhigh] = useState("")
  const [hrlow, setHrlow] = useState("")
  const [nocoin, setNocoin] = useState("")
  const [symbol, setSymbol] = useState([])
  // const [logicstate, setLogicstate] = useState([])
  const [resstate, setResstae] = useState([])
  const [resstate2, setResstae2] = useState(false)
  const [finda, setFineda] = useState([])
  const [binso, setBinso] = useState([])
  const [bybiso, setBYbiso] = useState([])
  const [okxso, setOkxso] = useState([])
  const [msxc, setMsxc] = useState([])
  const [bestprice, setBestprice] = useState([])
  const [mul, setMul] = useState();
  const [sortOption, setSortOption] = useState('A');
  const [hotCoins, setHotCoins] = useState([]);
  const [apiCall, setApiCall] = useState({
    method: "SUBSCRIBE",
    params: [
      "btcusdt@ticker",
      "bnbusdt@ticker",
      "ethusdt@ticker"
    ],
    id: 1
  });

  // console.log(sortOption, 'sortOption');

  const [userID, setUserID] = useState('')
  const [numCoins, setNumCoins] = useState(0)
  const [fav, setFav] = useState(false)
  const [oldPairs, setOldPairs] = useState([])
  const [oldExchange, setOldExchange] = useState("")
  const [backDrop, setBackDrop] = useState(true);
  const [rowData, setRowData] = useState({});
  const [noOfCoin, setNoOfCoins] = useState("");
  const handleCloseBackdrop = () => {
    setBackDrop(false);
  };
  const handleOpenBackdrop = () => {
    setBackDrop(true);
  };

  // const [allVal, setAllVal] = useState('')
  // const [ basevalue , setBasevalue ] = useState("")

  const [binanceSoc, setBinanceSoc] = useState()
  const [okxSoc, setokxSoc] = useState()
  const [bybitSoc, setBybitSoc] = useState()
  const [mexcSoc, setMexcSoc] = useState()

  const [load, setLoad] = useState(false)

  // console.log(symbol, 'symbol');
  const [simple, setSimple] = useState([])

  // const [perPage] = useState(10);
  const [perPage, setPerPage] = useState(10)
  const handleChange1 = (event, value) => {
    setPage(value);
    // setChangePage(true)
  };

  const handleSend = async (symbol) => {
    try {
      addNotification({
        title: "Notification",
        message: `${symbol} One Percentage High`,
        duration: 4000,
        native: true
      })
    } catch (error) {
      console.log("🚀 ~ handleSend ~ error:", error)
    }
  }

  const getHotCoins = async () => {



  };

  // useEffect( () => {
  //   // setTimeout( () => {
  //     getHotCoins();
  //   // }, 1000);
  // }, [apiCall]);


  const socket = async () => {

    const { data } = await Axios.get('/hotpairs', {
      headers: {
        Authorization: localStorage.getItem('Rikosta')
      }
    })

    var staticpairs = ["btcusdt@ticker", "bnbusdt@ticker", "ethusdt@ticker"];

    if (data?.success == true) {

      setHotCoins(data?.result);

      const hotPairs = [];

      const transformedHotPairs = data?.result?.map((symbol) => {
        return `${symbol?.symbol?.toLowerCase()}@ticker`;
      });

      // console.log(transformedHotPairs, "transformedHotPairs");

      staticpairs = [...transformedHotPairs];

      // setApiCall({ method: "SUBSCRIBE",
      //   params: transformedHotPairs,
      //   id: 1});

    }

    const ws = new WebSocket("wss://stream.binance.com:9443/ws");
    ws.onopen = (event) => {
      ws.send(JSON.stringify({
        method: "SUBSCRIBE",
        params: staticpairs,
        id: 1
      }));
    };

    ws.onmessage = function (event) {
      const json = JSON.parse(event?.data);
      // console.log(json, "jsonjsonjson");
      try {

        $(`.pricech-${json?.s}`).html(Number(json?.P).toFixed(4));
        $(`.close-${json?.s}`).html(Number(json?.c).toFixed(4));
        $(`.xtra-${json?.s}`).html(Number(json?.x).toFixed(4));
        $(`.open1-${json?.s}`).html(Number(json?.o).toFixed(4));
        $(`.vol1-${json?.s}`).html(Number(json?.v).toFixed(4));
        $(`.close-${json?.s}`).html(Number(json?.c).toFixed(4));


        // if (json.s === "BTCUSDT") {
        //   setDatatusd(json)
        //   var bnb = window.localStorage.getItem("btcusdt")
        //   if (bnb) {
        //     if (bnb <= Number(json?.P)) {
        //       // console.log("places")
        //       handleSend("BTCUSDT")
        //       window.localStorage.setItem("btcusdt", Number(json?.P) + 1)
        //     }
        //   } else {
        //     window.localStorage.setItem("btcusdt", Number(json?.P) + 1)
        //   }
        // }
        // else if (json.s === "BNBUSDT") {
        //   setDatabnb(json)
        //   var bnb = window.localStorage.getItem("bnbusdt")
        //   if (bnb) {
        //     if (bnb <= Number(json?.P)) {
        //       // console.log("places")
        //       handleSend("BNBUSDT")
        //       window.localStorage.setItem("bnbusdt", Number(json?.P) + 1)
        //     }
        //   } else {
        //     window.localStorage.setItem("bnbusdt", Number(json?.P) + 1)
        //   }
        // }
        // else if (json.s === "ETHUSDT") {
        //   setDatausdc(json)
        //   var bnb = window.localStorage.getItem("ethusdt")
        //   if (bnb) {
        //     if (bnb <= Number(json?.P)) {
        //       // console.log("places")
        //       handleSend("ETHUSDT")
        //       window.localStorage.setItem("ethusdt", Number(json?.P) + 1)
        //     }
        //   } else {
        //     window.localStorage.setItem("ethusdt", Number(json?.P) + 1)
        //   }
        // }

      } catch (err) {
        console.log(err);
      }
    };

    return () => {
      console.log('Cleaning up WebSocket connection');
      ws.close();
    }
  }

  useEffect(() => {
    socket()
  }, [])

  const searchData = useRef(0)


  const priceSocketUnsubscribe = async () => {
    try {
      binanceSoc.close()
    } catch (error) {
      console.log("🚀 ~ priceSocketUnsubscribe ~ error:", error)
    }
  }

  const okxPriceSocketUnSubscribe = async () => {
    try {
      okxSoc.close()
    } catch (error) {
      console.log("🚀 ~ okxPriceSocketUnSubscribe ~ error:", error)
    }
  }

  const bybitPriceSocketUnSubscribe = async () => {
    try {
      bybitSoc.close()
    } catch (error) {
      console.log("🚀 ~ okxPriceSocketUnSubscribe ~ error:", error)
    }
  }

  const mexcPriceSocketUnSubscribe = async () => {
    try {
      mexcSoc.close()
    } catch (error) {
      console.log("🚀 ~ okxPriceSocketUnSubscribe ~ error:", error)
    }
  }

  useEffect(() => {
    if (oldPairs?.length > 0) {
      if (oldExchange == "binance") {
        priceSocketUnsubscribe()
      } else if (oldExchange == "okx") {
        okxPriceSocketUnSubscribe()
      } else if (oldExchange == "bybit") {
        bybitPriceSocketUnSubscribe()
      } else if (oldExchange == "mexc") {
        mexcPriceSocketUnSubscribe()
      }
    }
  }, [oldPairs])


  const priceSocket = () => {
    const ws = new WebSocket("wss://stream.binance.com:9443/ws");
    setBinanceSoc(ws)
    ws.onopen = (event) => {
      const symbols = rows?.map(data => `${data?.symbol?.toLowerCase()}@ticker`);
      setSymbol(symbols)
      setSimple(symbols)
      const priceApicall = {
        "method": "SUBSCRIBE",
        "params": symbols,
        "id": 1
      }
      ws.send(JSON.stringify(priceApicall));
    };

    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);
      try {
        var obj = {
          symbol: json.s,
          close: json.c,
          openprice: json?.o,
          volume: json?.v,
          high: json?.h,
          low: json.l,
          exchange: "binance"
        }

        // setBinso((prevResstate) => {
        //   const existingItem = prevResstate.find(item => item.symbol === obj.symbol);

        //   if (existingItem) {
        //     // If symbol matches, update the item
        //     return prevResstate.map(item =>
        //       item.symbol === obj.symbol && item.close < obj.close
        //         ? { ...item, close: json?.c, openprice: json?.o, volume: json?.v, high: json?.h, low: json?.l, exchange: "binance" }
        //         : item
        //     );
        //   } else {
        //     // If no match, add the new object
        //     return [...prevResstate, obj];
        //   }
        // });
        $(`.price-${json?.s}`).html(Number(json?.c).toFixed(4));
        $(`.open-${json?.s}`).html(Number(json?.o).toFixed(4));
        $(`.vol-${json?.s}`).html(Number(json?.v).toFixed(4));
        $(`.high-${json?.s}`).html(Number(json?.h).toFixed(4));
        $(`.low-${json?.s}`).html(Number(json?.l).toFixed(4));
        $(`.24hc-${json?.s}`).html((Number(json?.l) - Number(json?.c) / Number(json?.c)) * 100);
        $(`.avg-${json?.s}`).html(((Number(json?.h) + Number(json?.l)) / 2).toFixed(8));


      } catch (error) {
        console.log(error, 'err');

      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket Connection Closed');
    };

    return () => {
      console.log('Cleaning up WebSocket connection');
      ws.close();
    };
  }


  const bybitsocker = () => {
    const client = new WebSocket('wss://stream.bybit.com/v5/public/spot');
    setBybitSoc(client)
    client.onopen = () => {
      console.log('WebSocket Client Connected');
      rows.forEach(pairData => {
        client.send(JSON.stringify({ op: 'subscribe', args: [`tickers.${pairData?.symbol}`] }));
      });
    };

    client.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data?.topic && data?.data) {
        const symbol = data?.topic?.split('.')[1];
        const json = data?.data;

        try {

          var obj = {
            symbol: json?.symbol,
            close: json?.lastPrice,
            openprice: json?.prevPrice24h,
            volume: json?.volume24h,
            high: json?.highPrice24h,
            low: json?.lowPrice24h,
            exchange: "bybit"
          }


          setBYbiso((prevResstate) => {
            const existingItem = prevResstate.find(item => item.symbol === obj.symbol);

            if (existingItem) {
              // If symbol matches, update the item
              return prevResstate.map(item =>
                item.symbol === obj.symbol
                  ? { ...item, close: json?.lastPrice, openprice: json?.prevPrice24h, volume: json?.volume24h, high: json?.highPrice24h, low: json?.lowPrice24h, exchange: "bybit" }
                  : item
              );
            } else {
              // If no match, add the new object
              return [...prevResstate, obj];
            }
          });

          $(`.price-${json?.symbol}`).html(json?.lastPrice);
          $(`.open-${json?.symbol}`).html(json?.prevPrice24h);
          $(`.vol-${json?.symbol}`).html(json?.volume24h);
          $(`.high-${json?.symbol}`).html(json?.highPrice24h);
          $(`.low-${json?.symbol}`).html(json?.lowPrice24h);
          $(`.24hc-${json?.symbol}`).html((json?.lowPrice24h - json?.lastPrice / json?.lastPrice) * 100);
          $(`.avg-${json?.symbol}`).html(((Number(json?.highPrice24h) + Number(json?.lowPrice24h)) / 2).toFixed(8));


        } catch (error) {
          console.log(error, 'err');

        }
        client.onerror = (error) => {
          console.error('WebSocket Error:', error);
        };

        client.onclose = () => {
          console.log('WebSocket Connection Closed');
        };

        return () => {
          console.log('Cleaning up WebSocket connection');
          client.close();
        };

      }
    };



  }

  const okxPriceSocket = () => {
    const okxws = new WebSocket('wss://ws.okx.com:8443/ws/v5/public?brokerId=198')
    setokxSoc(okxws)
    okxws.onopen = (event) => {
      const startIndex = (page - 1) * perPage;
      const endIndex = startIndex + perPage;
      const currentPageData = rows?.slice(startIndex, endIndex) || [];
      const symbols = rows?.map(data => {
        return (
          {
            "channel": "tickers",
            "instId": data?.baseCoin + "-" + data?.quoteCoin
          }
        )
      })

      const priceApicall = {
        "op": "subscribe",
        "args": symbols
      }
      okxws.send(JSON.stringify(priceApicall));
    }
    okxws.onmessage = function (event) {
      var response = JSON.parse(event.data);
      if (response?.data?.length > 0) {
        var json = response?.data[0];

        // console.log(json,"okxjson");

        var obj = {
          symbol: json?.instId.split("-")[0] + json?.instId.split("-")[1],
          close: json?.last,
          openprice: json?.open24h,
          volume: json?.vol24h,
          high: json?.high24h,
          low: json?.low24h
        }
        // setOkxso((prevResstate) => {
        //   const existingItem = prevResstate.find(item => item.symbol === obj.symbol);

        //   if (existingItem) {
        //     // If symbol matches, update the item
        //     return prevResstate.map(item =>
        //       item.symbol === obj.symbol
        //         ? { ...item, close: json?.last, openprice: json?.open24h, volume: json?.vol24h, high: json?.high24h, low: json?.low24h, exchange: "okx" }
        //         : item
        //     );
        //   } else {
        //     // If no match, add the new object
        //     return [...prevResstate, obj];
        //   }
        // });
        $(`.price-${obj?.symbol}`).html(obj?.close);
        $(`.open-${obj?.symbol}`).html(obj?.openprice);
        $(`.vol-${obj?.symbol}`).html(obj?.volume);
        $(`.high-${obj?.symbol}`).html(obj?.high);
        $(`.low-${obj?.symbol}`).html(obj?.low);
        $(`.24hc-${obj?.symbol}`).html((obj?.low - json?.close / json?.close) * 100);
        $(`.avg-${json?.symbol}`).html(((Number(obj?.high) + Number(obj?.low)) / 2).toFixed(8));

      }
    }
    okxws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };
    okxws.onclose = () => {
      console.log('WebSocket Connection Closed okx');
    };
    return () => {
      console.log('Cleaning up WebSocket connection');
      okxws.close();
    };
  }

  const mexcPriceSocket = () => {
    const mexcws = new WebSocket('wss://wbs.mexc.com/ws')
    setMexcSoc(mexcws)
    let priceHtml;
    let lowPriceHtml;
    let highPriceHtml;
    mexcws.onopen = (event) => {
      const startIndex = (page - 1) * perPage;
      const endIndex = startIndex + perPage;
      const currentPageData = rows?.slice(startIndex, endIndex) || [];
      const symbols = currentPageData.map(data => {
        return (
          `spot@public.miniTicker.v3.api@${data.symbol}@UTC+5`
        )
      })

      const priceApicall = { "method": "SUBSCRIPTION", "params": symbols }
      mexcws.send(JSON.stringify(priceApicall));
    }
    mexcws.onmessage = function (event) {
      var response = JSON.parse(event.data);
      if (response?.d) {
        var json = response?.d;


        var obj = {
          symbol: json.s,
          close: json.c,
          openprice: json?.o,
          volume: json?.v,
          high: json?.h,
          low: json.l
        }

        // setMsxc((prevResstate) => {
        //   const existingItem = prevResstate.find(item => item.symbol === obj.symbol);

        //   if (existingItem) {
        //     // If symbol matches, update the item
        //     return prevResstate.map(item =>
        //       item.symbol === obj.symbol
        //         ? { ...item, close: json?.p, openprice: json?.tr, volume: json?.v, high: json?.h, low: json?.l, exchange: "mexc" }
        //         : item
        //     );
        //   } else {
        //     // If no match, add the new object
        //     return [...prevResstate, obj];
        //   }
        // });

        $(`.price-${json?.s}`).html(json?.p);
        $(`.open-${json?.s}`).html(json?.tr);
        $(`.vol-${json?.s}`).html(json?.v);
        $(`.high-${json?.s}`).html(json?.h);
        $(`.low-${json?.s}`).html(json?.l);
        $(`.24hc-${json?.s}`).html((json?.l - json?.p / json?.p) * 100);
        $(`.avg-${json?.s}`).html(((Number(json?.h) + Number(json?.l)) / 2).toFixed(8));

      }
    }
    mexcws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };
    mexcws.onclose = () => {
      console.log('WebSocket Connection Closed');
    };
    return () => {
      console.log('Cleaning up WebSocket connection');
      mexcws.close();
    };
  }

  useEffect(() => {
    if (rows?.length > 0) {
      // bybitsocker()
      // priceSocket()
      // okxPriceSocket()
      // mexcPriceSocket()
      if (exchange == "binance") {
        priceSocket()
      } else if (exchange == "bybit") {
        bybitsocker()
      } else if (exchange == 'okx') {
        okxPriceSocket()
      } else if (exchange == 'mexc') {
        mexcPriceSocket()
      }
    }
    return () => {
      // console.log('socket cleanup');
      // const okxws = new WebSocket('wss://ws.okx.com:8443/ws/v5/public?brokerId=198')
      // okxws.close()
      // const mexcws = new WebSocket('wss://wbs.mexc.com/ws')
      // mexcws.close()
    }
  }, [page, rows])


  const tbl2Data = async () => {
    // console.log(resstate.length,"resstateresstate");
    if (resstate.length > 0) {

      // console.log(resstate.length,"resstateresstate");

      for (var ii = 0; ii < resstate.length; ii++) {

        const priceElementBin = document.querySelector(`.bin-price-${resstate[ii]?.symbol}`);
        const priceElementByb = document.querySelector(`.byb-price-${resstate[ii]?.symbol}`);
        const priceElementOkx = document.querySelector(`.okx-price-${resstate[ii]?.symbol}`);
        const priceElementMex = document.querySelector(`.mex-price-${resstate[ii]?.symbol}`);

        let tbl2Prices = [];

        if ((priceElementBin != null) && (priceElementByb != null) && (priceElementOkx != null) && (priceElementMex != null)) {

          const priceBin = priceElementBin.textContent;
          const priceByb = priceElementByb.textContent;
          const priceOkx = priceElementOkx.textContent;
          const priceMex = priceElementMex.textContent;
          var exchange1;

          var maxPrice1 = Math.max(priceBin, priceByb, priceOkx);

          if (maxPrice1 == priceBin) {
            exchange1 = "bin";
          } else if (maxPrice1 == priceByb) {
            exchange1 = "byb";
          } else if (maxPrice1 == priceOkx) {
            exchange1 = "okx"
          } else if (maxPrice1 == priceMex) {
            exchange1 = "mex"
          }

          var obj1 = {
            close: document.querySelector(`.${exchange1}-price-${resstate[ii]?.symbol}`).textContent,
            openprice: document.querySelector(`.${exchange1}-open-${resstate[ii]?.symbol}`).textContent,
            volume: document.querySelector(`.${exchange1}-vol-${resstate[ii]?.symbol}`).textContent,
            high: document.querySelector(`.${exchange1}-high-${resstate[ii]?.symbol}`).textContent,
            low: document.querySelector(`.${exchange1}-low-${resstate[ii]?.symbol}`).textContent,
            exchange: exchange1 == "bin" ? "binance" : exchange1 == "byb" ? "bybit" : exchange1 == "okx" ? "okx" : "mexc",
            symbol: resstate[ii]?.symbol
          };

          tbl2Prices.push(obj1);
          // console.log(tbl2Prices,"tbl2Prices");

          setResstae((prevResstate) => {
            // Merge new updates with the existing state
            const updatedState = tbl2Prices.map((obj) => {
              const existingItemIndex = prevResstate.findIndex((item) => item?.symbol === obj?.symbol);

              if (existingItemIndex != -1) {
                // Update the existing object in the state
                return { ...prevResstate[existingItemIndex], ...obj };
              } else {
                // Add the new object to the state
                return obj;
              }
            });

            // Filter out items that already exist in prevResstate to avoid duplication
            return [
              ...prevResstate.map(item => updatedState.find(update => update.symbol === item.symbol) || item),
              ...updatedState.filter(item => !prevResstate.some(state => state.symbol === item.symbol))
            ];

          });

        }


      }

    }

  };


  const bestdata = async () => {
    //     for (let i = 0; i <= rows?.length; i++) {
    //       const filteredArray = combinedArray.filter(obj => obj.symbol === rows[i]?.symbol);
    //       const maxPriceObj = filteredArray.reduce((maxObj, currentObj) => {
    //         return currentObj.close > maxObj.close ? currentObj : maxObj;
    //       }, filteredArray[0]);
    //       setBestprice((prevResstate) => {
    //         // if(search) {

    // // sestprice

    //         // } else {
    //         const existingItemIndex = prevResstate.findIndex(item => item?.symbol === maxPriceObj?.symbol);
    //         if (existingItemIndex !== -1) {
    //           return prevResstate.map((item, index) =>
    //             index === existingItemIndex
    //               ? {
    //                 ...item,
    //                 close: maxPriceObj?.close,
    //                 openprice: maxPriceObj?.openprice,
    //                 volume: maxPriceObj?.volume,
    //                 high: maxPriceObj?.high,
    //                 low: maxPriceObj?.low,
    //                 exchange: maxPriceObj?.exchange
    //               }
    //               : item
    //           );
    //         } else {
    //           return [...prevResstate, maxPriceObj];
    //         }
    //         // }
    //         // }
    //       });

    //       // console.log(bestprice,"data");



    //     }



    // working code for best price of 4 exchanges
    // for (var i = 0; i < bestprice?.length; i++) {

    //   var rows = bestprice;

    //   const priceElementBin = document.querySelector(`.bin-price-${rows[i]?.symbol}`);
    //   const priceElementByb = document.querySelector(`.byb-price-${rows[i]?.symbol}`);
    //   const priceElementOkx = document.querySelector(`.okx-price-${rows[i]?.symbol}`);
    //   const priceElementMex = document.querySelector(`.mex-price-${rows[i]?.symbol}`);


    //   let updatedBestPrices = [];

    //   if ((priceElementBin) && (priceElementByb) && (priceElementOkx) && (priceElementMex)) {

    //     var priceBin = priceElementBin.textContent;
    //     var priceByb = priceElementByb.textContent;
    //     var priceOkx = priceElementOkx.textContent;
    //     var priceMex = priceElementMex.textContent;
    //     var exchange;

    //     var maxPrice = Math.max(priceBin, priceByb, priceOkx, priceMex);

    //     if (maxPrice == priceBin) {
    //       exchange = "bin";
    //     } else if (maxPrice == priceByb) {
    //       exchange = "byb";
    //     } else if (maxPrice == priceOkx) {
    //       exchange = "okx";
    //     } else if (maxPrice == priceMex) {
    //       exchange = "mex"
    //     }

    //     var obj = {
    //       close: document.querySelector(`.${exchange}-price-${rows[i]?.symbol}`).textContent,
    //       openprice: document.querySelector(`.${exchange}-open-${rows[i]?.symbol}`).textContent,
    //       volume: document.querySelector(`.${exchange}-vol-${rows[i]?.symbol}`).textContent,
    //       high: document.querySelector(`.${exchange}-high-${rows[i]?.symbol}`).textContent,
    //       low: document.querySelector(`.${exchange}-low-${rows[i]?.symbol}`).textContent,
    //       exchange: exchange == "bin" ? "binance" : exchange == "byb" ? "bybit" : exchange == "okx" ? "okx" : "mexc",
    //       symbol: rows[i]?.symbol
    //     };


    //     updatedBestPrices.push(obj);

    //     setBestprice((prevResstate) => {
    //       // Merge new updates with the existing state
    //       const updatedState = updatedBestPrices.map((obj) => {
    //         const existingItemIndex = prevResstate.findIndex((item) => item?.symbol === obj?.symbol);

    //         if (existingItemIndex !== -1) {
    //           // Update the existing object in the state
    //           return { ...prevResstate[existingItemIndex], ...obj };
    //         } else {
    //           // Add the new object to the state
    //           return obj;
    //         }
    //       });

    //       // Filter out items that already exist in prevResstate to avoid duplication
    //       return [
    //         ...prevResstate.map(item => updatedState.find(update => update.symbol === item.symbol) || item),
    //         ...updatedState.filter(item => !prevResstate.some(state => state.symbol === item.symbol))
    //       ];

    //     });

    //   }

    // }
    // alert(noOfCoin);

    if (fav == true) {

      setBestprice([]);

      rows?.forEach((symbol) => {
        const da = {
          image: symbol?.imageurl,
          baseCoin: symbol?.baseCoin,
          quoteCoin: symbol?.quoteCoin,
          close: "0",
          symbol: symbol?.symbol,
          openprice: "0",
          volume: "0",
          high: "0",
          low: "0",
          exchange: "",
          status: true,
          users_id: symbol?.users_id
        };

        setBestprice((prevItems = []) => {

          if (symbol?.users_id.includes(userID)) {
            return [...prevItems, da];
          }

          return prevItems;

        });
      });
      return;
    } else if (noOfCoin != "") {
      return;
    }
    // else if (fav == false) {

    //calculation and pic the pair values greater than 3 
    let tot = 0;
    rows?.forEach((symbol) => {
      const highPrice = Number(document.querySelector(`.high-${symbol?.symbol}`)?.textContent);
      const lowPrice = Number(document.querySelector(`.low-${symbol?.symbol}`)?.textContent);
      const currentprice = Number(document.querySelector(`.price-${symbol?.symbol}`)?.textContent);
      const percentageDifference = ((highPrice - lowPrice) / 2 / highPrice) * 100;
      var average = ((highPrice + lowPrice) / 2);
      var da = {
        image: symbol?.imageurl,
        baseCoin: symbol?.baseCoin,
        quoteCoin: symbol?.quoteCoin,
        close: "0",
        symbol: symbol?.symbol,
        openprice: "0",
        volume: "0",
        high: "0",
        low: "0",
        exchange: "",
        status: true,
        users_id: symbol?.users_id,
        // avg: average
      }
      if (bestprice.length > 0) {

        setBestprice(prevItems => {
          const exists = prevItems?.some(item => item.symbol === symbol?.symbol);
          if (percentageDifference >= 3 && (!below || (below && currentprice <= average))) {
            if (!exists) {
              tot++;
              return [...prevItems, da];
            } else {
              tot++;
              return prevItems?.map(item =>
                item.symbol === symbol?.symbol ? { ...item, status: true } : item
              );
            }
          } else {
            return prevItems?.filter(item => item.symbol !== symbol?.symbol);
          }
        });

      }

    });

    // }
    // console.log(tot, below, "tott");
  }

  const bestData1 = async () => {

    let tot = 0;

    rows?.forEach((symbol) => {
      const highPrice = Number(document.querySelector(`.high-${symbol?.symbol}`)?.textContent);
      const lowPrice = Number(document.querySelector(`.low-${symbol?.symbol}`)?.textContent);
      const currentprice = Number(document.querySelector(`.price-${symbol?.symbol}`)?.textContent);
      const percentageDifference = ((highPrice - lowPrice) / 2 / highPrice) * 100;
      var average = ((highPrice + lowPrice) / 2);
      var da = {
        image: symbol?.imageurl,
        baseCoin: symbol?.baseCoin,
        quoteCoin: symbol?.quoteCoin,
        close: "0",
        symbol: symbol?.symbol,
        openprice: "0",
        volume: "0",
        high: "0",
        low: "0",
        exchange: "",
        status: true,
        users_id: symbol?.users_id,
        // avg: average
      }
      // if (bestprice.length > 0) {

      setBestprice(prevItems => {
        const exists = prevItems?.some(item => item.symbol === symbol?.symbol);
        if (percentageDifference >= 3 && (!below || (below && currentprice <= average))) {
          if (!exists) {
            tot++;
            return [...prevItems, da];
          } else {
            tot++;
            return prevItems?.map(item =>
              item.symbol === symbol?.symbol ? { ...item, status: true } : item
            );
          }
        } else {
          return prevItems?.filter(item => item.symbol !== symbol?.symbol);
        }
      });

      // }

    });

  };

  const GetFavTokens = async (favv) => {

    setFav(favv);
    setBestprice([]);

    if (favv == true) {

      rows?.forEach((symbol) => {
        const da = {
          image: symbol?.imageurl,
          baseCoin: symbol?.baseCoin,
          quoteCoin: symbol?.quoteCoin,
          close: "0",
          symbol: symbol?.symbol,
          openprice: "0",
          volume: "0",
          high: "0",
          low: "0",
          exchange: "",
          status: true,
          users_id: symbol?.users_id
        };

        setBestprice((prevItems = []) => {

          if (symbol?.users_id.includes(userID)) {
            return [...prevItems, da];
          }

          return prevItems;

        });

      });
    } else {
      bestData1();
    }
  };

  const [below, setBelow] = useState(false)

  useEffect(() => {
    if (rows?.length > 0) {
      const intervalId = setInterval(() => {
        handleCloseBackdrop()
        bestdata();
      }, 5000);

      return () => clearInterval(intervalId); // Clear the interval when component unmounts or `rows` changes
    }
  }, [rows, below, fav, noOfCoin]);

  const [open, setOpen] = useState(false);
  const handleOpen = (symbol) => {
    // console.log("🚀 ~ handleOpen ~ symbol:", symbol)
    if (exchange === "binance") {
      // const data = symbol?.replace("USDT", "")
      // const data = symbol?.replace(coin, "")
      const data = `${symbol?.baseCoin}_${symbol?.quoteCoin}`
      // console.log("🚀 ~ handleOpen ~ data:", data)
      window.open(`https://www.binance.com/en/trade/${data}?type=spot`, "_blank")
    } else if (exchange === "bybit") {
      // const data = symbol?.replace("USDT", "")
      // const data = symbol?.replace(coin, "")
      const data = `${symbol?.baseCoin}/${symbol?.quoteCoin}`
      // console.log("🚀 ~ handleOpen ~ data:", data)
      window.open(`https://www.bybit.com/en/trade/spot/${data}`, "_blank")
    }
    else if (exchange === "okx") {
      // const data = symbol?.replace("USDT", "").toLowerCase()
      // const data = symbol?.replace(coin, "").toLowerCase()
      const data = `${symbol?.baseCoin}-${symbol?.quoteCoin}`?.toLowerCase()
      window.open(`https://www.okx.com/trade-spot/${data}`, "_blank")
    }
    else if (exchange === "mexc") {
      // const data = symbol?.replace("USDT", "")
      // const data = symbol?.replace(coin, "")
      const data = `${symbol?.baseCoin}_${symbol?.quoteCoin}`
      window.open(`https://www.mexc.com/exchange/${data}?_from=header`, "_blank")
    }

  }
  const handleClose = () => {
    setRowData({});
    setOpen(false);
  }

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [state, setState] = useState({
    top: false,
  });



  const toggleDrawer = (anchor, open) => async (event) => {

    // const { data } = await Axios.get('/users/subscribedPlan', {
    //   headers: {
    //     Authorization: localStorage.getItem('Rikosta')
    //   }
    // })
    // if (data?.success === false) {
    //   toast.error(data?.message)
    //   setTimeout(() => {
    //     navigate("/profile", { state: "1" })
    //   }, 1000);
    // } else {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
    // }
  };

  const handleOptionChange = async (e) => {
    setPage(1)
    setCoin(e?.target?.value)
    // console.log(e?.target?.value, "e?.target?.value");
  }

  const handleSortOption = async (val) => {
    var order
    if (val == 'A') {
      order = 'Z'
    } else {
      order = 'A'
    }

    setSortOption(order)
  }

  // console.log(sortOption, 'sort');


  const [status, setStatus] = useState(false)

  const checkSubscription = async () => {
    try {
      const { data } = await Axios.get('/users/subscribedPlan', {
        headers: {
          Authorization: localStorage.getItem('Rikosta')
        }
      })
      if (data?.success === false) {
        toast.error(data?.message)
        setTimeout(() => {
          navigate("/profile", { state: { value: "1" } })
        }, 1000);
      } else {
        setStatus(true)
      }
    } catch (error) {
      console.log("🚀 ~ checkSubscription ~ error:", error)
    }
  }

  useEffect(() => {
    checkSubscription();
  }, []);

  const getPairs = async () => {
    try {
      const { data } = await Axios.post('/getPairs', { coin: coin, exchange: exchange }, {
        headers: {
          Authorization: localStorage.getItem('Rikosta')
        }
      })
      if (data?.success) {

        if (search) {
          // console.log(search,"searchsearchsearch");
          setBestprice([])
          setBYbiso([])
          setBinso([])
          setOkxso([])
          setMsxc([])
          const filtered = [];
          var searchData = [];
          for (let i = 0; i < data?.result.length; i++) {

            if (data?.result[i]?.symbol.includes(search)) {
              let da = {
                image: data?.result[i]?.imageurl,
                close: "0",
                symbol: data?.result[i]?.symbol,
                openprice: "0",
                volume: "0",
                high: "0",
                low: "0",
                exchange: "",
                status: true,
                users_id: data?.result[i]?.users_id

              }
              searchData.push(da);
              // setBestprice(bestprice => [ da, ...bestprice])
              // setBYbiso(bybiso => [ da, ...bybiso])
              // setBinso(binso => [ da, ...binso])
              // setOkxso(okxso => [ da, ...okxso])
              // setMsxc(msxc => [ da, ...msxc])

              // filtered.push(data.result[i]);
              // filtered.push(da);
            }
          }


          setBestprice([...searchData]);
          // setBestprice(bestprice => [...searchData])
          setBYbiso(bybiso => [...searchData])
          setBinso(binso => [...searchData])
          setOkxso(okxso => [...searchData])
          setMsxc(msxc => [...searchData])
          setRows(...searchData);

          // setBestprice( [...searchData])
          // setBYbiso([...searchData])
          // setBinso([...searchData])
          // setOkxso([...searchData])
          // setMsxc([...searchData])
          // setRows(...searchData);
        }
        else {
          var da = {};
          var respoData = [];
          for (let i = 0; i < data?.result?.length; i++) {
            if (exchange == "okx") {
              da = {
                image: data?.result[i]?.imageurl,
                baseCoin: data?.result[i]?.baseCoin,
                quoteCoin: data?.result[i]?.quoteCoin,
                close: "0",
                symbol: data?.result[i]?.symbol?.split("-")?.join(""),
                openprice: "0",
                volume: "0",
                high: "0",
                low: "0",
                exchange: "",
                status: true,
                users_id: data?.result[i]?.users_id
              }
            } else {
              const isImageWorking = (url) => new Promise(resolve => { const img = new Image(); img.onload = () => resolve(true); img.onerror = () => resolve(false); img.src = url; });
              da = {
                // image: await isImageWorking(data?.result[i]?.imageurl) ? data?.result[i]?.imageurl : "",
                image: data?.result[i]?.imageurl,
                baseCoin: data?.result[i]?.baseCoin,
                quoteCoin: data?.result[i]?.quoteCoin,
                close: "0",
                symbol: data?.result[i]?.symbol,
                openprice: "0",
                volume: "0",
                high: "0",
                low: "0",
                exchange: "",
                status: true,
                users_id: data?.result[i]?.users_id
              }
            }
            respoData.push(da);
          }
          setOldPairs(rows)
          setBestprice(respoData)
          setRows(respoData)
        }
      }
    }
    catch (error) {
      console.log(error, 'err');
    }
  }

  const getPairs1 = async (coins) => {
    try {
      const { data } = await Axios.post('/getPairs', { coin: coin, exchange: exchange }, {
        headers: {
          Authorization: localStorage.getItem('Rikosta')
        }
      })
      if (data?.success) {

        // if (search) {
        //   setBestprice([])
        //   setBYbiso([])
        //   setBinso([])
        //   setOkxso([])
        //   setMsxc([])
        //   const filtered = [];
        //   var searchData = [];
        //   // for (let i = 0; i < data?.result.length; i++) {
        //   for (let i = 0; i < bestprice.length; i++) {

        //     if (bestprice[i]?.symbol.includes(search)) {

        //       let da = {
        //         image: bestprice[i]?.image ? bestprice[i]?.image : " ",
        //         close: "0",
        //         symbol: bestprice[i]?.symbol,
        //         openprice: "0",
        //         volume: "0",
        //         high: "0",
        //         low: "0",
        //         exchange: ""

        //       }
        //       searchData.push(da);

        //     }
        //   }


        //   setBestprice(bestprice => [...searchData])
        //   setBYbiso(bybiso => [...searchData])
        //   setBinso(binso => [...searchData])
        //   setOkxso(okxso => [...searchData])
        //   setMsxc(msxc => [...searchData])
        //   setRows(...searchData);

        // }
        // else {
        var da = {};
        var respoData = [];
        // var loopLen = coins <= bestprice.length ? coins : bestprice.length;
        for (let i = 0; i < bestprice.length; i++) {

          if (exchange == "okx") {

            da = {
              image: bestprice[i]?.image ? bestprice[i]?.image : " ",
              baseCoin: bestprice[i]?.baseCoin,
              quoteCoin: bestprice[i]?.quoteCoin,
              close: "0",
              symbol: bestprice[i]?.symbol,
              openprice: "0",
              volume: "0",
              high: "0",
              low: "0",
              exchange: "",
              status: true,
              users_id: bestprice[i]?.users_id
            }

          } else {

            const isImageWorking = (url) => new Promise(resolve => { const img = new Image(); img.onload = () => resolve(true); img.onerror = () => resolve(false); img.src = url; });

            da = {
              // image: await isImageWorking(data?.result[i]?.imageurl) ? data?.result[i]?.imageurl : "",
              image: bestprice[i]?.image ? bestprice[i]?.image : " ",
              baseCoin: bestprice[i]?.baseCoin,
              quoteCoin: bestprice[i]?.quoteCoin,
              close: "0",
              symbol: bestprice[i]?.symbol,
              openprice: "0",
              volume: "0",
              high: "0",
              low: "0",
              exchange: "",
              status: true,
              users_id: bestprice[i]?.users_id
            }

          }
          // alert(coins);
          if (respoData.length < coins) {

            if ((bestprice[i]?.symbol != "") && (bestprice[i]?.symbol != undefined)) {
              respoData.push(da);
            }

          } else {
            break;
          }

        }
        setOldPairs(rows)
        setBestprice(respoData)
        setRows(respoData)
        // }
      }
    }
    catch (error) {
      console.log(error, 'err');
    }
  }

  useEffect(() => {
    if (rows?.length > 0) {
      const timer = setTimeout(() => {
        // console.log('logged after 5sec');
        // setChangePage(false)
      }, 3000);

      return () => clearTimeout(timer)
    }
  }, [page])

  useEffect(() => {
    if (status) {
      getPairs()
    }
    if (perPage == '') {
      setPerPage(10)
    }
  }, [coin, search, exchange, status, sortOption, perPage])

  // console.log(perPage, 'perpage');

  useEffect(() => {
    const user_ID = JSON.parse(localStorage.getItem('user'));
    setUserID(user_ID?._id);
  }, []);


  // original
  const algorithm = async () => {

    if (hrhigh === "") {
      toast.error("value should not be empty")
    }
    else if (hrlow === "") {
      toast.error("value should not be empty")
    } else if (nocoin === "") {
      toast.error("No.of.coin To Display Should Not Be Empty")
    }
    else {

      setLoad(true)
      const timer = setTimeout(() => {
        setLoad(false)
        setResstae2(true)
      }, 3000);

      let sub = hrhigh - hrlow
      let div = sub / hrhigh
      let mul = div * 100
      setMul(mul);
      if (mul >= 3) {
        searchData.current = Number(mul)
        var bestprice1 = bestprice;
        setBestprice([]);
        const dataBestPrice = [];
        for (let i = 0; i <= bestprice1.length; i++) {
          var dat = {
            symbol: bestprice1[i]?.symbol,
            close: bestprice1[i]?.close,
            openprice: bestprice1[i]?.openprice,
            volume: bestprice1[i]?.volume,
            high: bestprice1[i]?.high,
            low: bestprice1[i]?.low,
            exchange: bestprice1[i]?.exchange
          }
          // console.log(dat, "datdatdat");
          if (dat?.close != undefined) {
            if ((dat?.close > mul) && (mul != 0)) {
              dataBestPrice.push(dat);
              // setBestprice((prevResstate) => {
              // const existingItem = prevResstate.find(item => item?.symbol === dat?.symbol);
              // if (existingItem) {
              // If symbol matches, update the item
              // return prevResstate.map(item =>
              //   item.symbol === dat?.symbol
              //     ? { ...item, symbol: bestprice[i]?.symbol, close: bestprice[i]?.close, openprice: bestprice[i]?.openprice, volume: bestprice[i]?.volume, high: bestprice[i]?.high, low: bestprice[i].low, exchange: bestprice[i].exchange }
              //     : item
              // );
              // } else {
              // If no match, add the new object
              // return [...prevResstate, dat];
              // }
              // });
            }
          }
        }

        setBestprice(dataBestPrice);
        // console.log(dataBestPrice,"dataBestPrice");

      } else {
        toast.error("No Pairs Found")
      }

      return () => clearTimeout(timer)

    }

  }

  // new change
  // const algorithm = async () => {
  //   if (hrhigh === "") {
  //     toast.error("High value should not be empty");
  //   } else if (hrlow === "") {
  //     toast.error("Low value should not be empty");
  //   } else if (nocoin === "") {
  //     toast.error("No. of coins to display should not be empty");
  //   } else {
  //     // alert(nocoin);
  //     setLoad(true);
  //     const timer = setTimeout(() => {
  //       setLoad(false);
  //       setResstae2(true);
  //     }, 3000);

  //     let sub = hrhigh - hrlow;
  //     let div = sub / hrhigh;
  //     let mul = div * 100;

  //     if (mul >= 3) {
  //       searchData.current = Number(mul);

  //       // Filter the data based on the criteria
  //       const uniquePairs = [];

  //       for (let i = 0; i < bestprice.length; i++) {
  //         const dat = {
  //           symbol: bestprice[i]?.symbol,
  //           close: bestprice[i]?.close,
  //           openprice: bestprice[i]?.openprice,
  //           volume: bestprice[i]?.volume,
  //           high: bestprice[i]?.high,
  //           low: bestprice[i]?.low,
  //           exchange: bestprice[i]?.exchange
  //         };

  //         if (dat?.close !== undefined && dat?.close > searchData?.current) {
  //           const existingItem = uniquePairs.find(item => item?.symbol === dat?.symbol);

  //           // Avoid duplicates by updating existing entries
  //           if (!existingItem) {
  //             uniquePairs.push(dat);
  //           } else {
  //             // Update if the existing close price is lower
  //             if (dat?.close > existingItem?.close) {
  //               const index = uniquePairs.findIndex(item => item?.symbol === dat?.symbol);
  //               uniquePairs[index] = dat;
  //             }
  //           }
  //         }
  //       }

  //       // Set the final filtered list without duplicates
  //       if (uniquePairs.length > 0) {
  //         setResstae(uniquePairs);
  //       } else {
  //         toast.error("No pairs found that meet the criteria");
  //       }

  //     } else {
  //       toast.error("No pairs found");
  //     }

  //     return () => clearTimeout(timer);
  //   }
  // };

  // new change 2 working with live price update
  // const algorithm = async () => {
  //   if (hrhigh === "") {
  //     toast.error("High value should not be empty");
  //   } else if (hrlow === "") {
  //     toast.error("Low value should not be empty");
  //   } else if (nocoin === "") {
  //     toast.error("No. of coins to display should not be empty");
  //   } else {
  //     // alert(nocoin);
  //     setLoad(true);
  //     const timer = setTimeout(() => {
  //       setLoad(false);
  //       setResstae2(true);
  //     }, 3000);

  //     let sub = hrhigh - hrlow;
  //     let div = sub / hrhigh;
  //     let mul = div * 100;
  //     console.log(mul,"mulmulmul");
  //     setMul(mul);

  //     if (mul >= 3) {
  //       searchData.current = Number(mul);

  //       // Filter the data based on the criteria
  //       const uniquePairs = [];

  //       for (let i = 0; i < bestprice.length; i++) {
  //         const dat = {
  //           symbol: bestprice[i]?.symbol,
  //           close: bestprice[i]?.close,
  //           openprice: bestprice[i]?.openprice,
  //           volume: bestprice[i]?.volume,
  //           high: bestprice[i]?.high,
  //           low: bestprice[i]?.low,
  //           exchange: bestprice[i]?.exchange,
  //         };

  //         if (dat?.close !== undefined && dat?.close > searchData?.current) {
  //           const existingItem = uniquePairs.find(item => item?.symbol === dat?.symbol);

  //           // Avoid duplicates by updating existing entries
  //           if (!existingItem) {
  //             uniquePairs.push(dat);
  //           } else {
  //             // Update if the existing close price is lower
  //             if (dat?.close > existingItem?.close) {
  //               const index = uniquePairs.findIndex(item => item?.symbol === dat?.symbol);
  //               uniquePairs[index] = dat;
  //             }
  //           }
  //         }
  //       }

  //       // Additional logic to update resstate based on DOM elements
  //       if (uniquePairs.length > 0) {
  //         // Iterate through the filtered uniquePairs
  //         uniquePairs.forEach((pair) => {
  //           const priceElementBin = document.querySelector(`.bin-price-${pair?.symbol}`);
  //           const priceElementByb = document.querySelector(`.byb-price-${pair?.symbol}`);
  //           const priceElementOkx = document.querySelector(`.okx-price-${pair?.symbol}`);
  //           const priceElementMex = document.querySelector(`.mex-price-${pair?.symbol}`);


  //           let tbl3Prices = [];

  //           if ((priceElementBin != null) && (priceElementByb != null) && (priceElementOkx != null) && (priceElementMex != null)) {
  //             const priceBin = priceElementBin.textContent;
  //             const priceByb = priceElementByb.textContent;
  //             const priceOkx = priceElementOkx.textContent;
  //             const priceMex = priceElementMex.textContent;
  //             var exchange1;

  //             var maxPrice1 = Math.max(priceBin, priceByb, priceOkx, priceMex);

  //             if (maxPrice1 == priceBin) {
  //               exchange1 = "bin";
  //             } else if (maxPrice1 == priceByb) {
  //               exchange1 = "byb";
  //             } else if (maxPrice1 == priceOkx) {
  //               exchange1 = "okx";
  //             } else if (maxPrice1 == priceMex) {
  //               exchange1 = "mex";
  //             }

  //             var obj1 = {
  //               close: document.querySelector(`.${exchange1}-price-${pair?.symbol}`).textContent,
  //               openprice: document.querySelector(`.${exchange1}-open-${pair?.symbol}`).textContent,
  //               volume: document.querySelector(`.${exchange1}-vol-${pair?.symbol}`).textContent,
  //               high: document.querySelector(`.${exchange1}-high-${pair?.symbol}`).textContent,
  //               low: document.querySelector(`.${exchange1}-low-${pair?.symbol}`).textContent,
  //               exchange: exchange1 == "bin" ? "binance" : exchange1 == "byb" ? "bybit" : exchange1 == "okx" ? "okx" : "mexc",
  //               symbol: pair?.symbol
  //             };

  //             tbl3Prices.push(obj1);

  //             // Update the resstate by merging the new data
  //             setResstae((prevResstate) => {
  //               const updatedState = tbl3Prices.map((obj) => {
  //                 const existingItemIndex = prevResstate.findIndex((item) => item?.symbol === obj?.symbol);

  //                 if (existingItemIndex != -1) {
  //                   // Update the existing object in the state
  //                   return { ...prevResstate[existingItemIndex], ...obj };
  //                 } else {
  //                   // Add the new object to the state
  //                   return obj;
  //                 }
  //               });

  //               return [
  //                 ...prevResstate.map(item => updatedState.find(update => update.symbol === item.symbol) || item),
  //                 ...updatedState.filter(item => !prevResstate.some(state => state.symbol === item.symbol))
  //               ];
  //             });

  //             tbl2Data()
  //           }
  //         });

  //         // toast.success("Pairs successfully updated");
  //       } else {
  //         toast.error("No pairs found that meet the criteria");
  //       }
  //     } else {
  //       toast.error("No pairs found");
  //     }

  //     return () => clearTimeout(timer);
  //   }
  // };

  useEffect(() => {
    tbl2Data()
  }, [resstate])

  const handleCloseAnchor = () => {
    setState({ top: false })
    // if (resstate.length > 0) {
    setLoad(true)
    const timer = setTimeout(() => {
      setLoad(false)
      setResstae2(false)
    }, 3000);
    return () => clearTimeout(timer)
    // }
  };

  const handleFav = async (pair) => {

    const payload = {
      symbol: pair,
      exchange: exchange
    }

    try {
      const { data } = await Axios.post('/addfav', payload, {
        headers: {
          Authorization: localStorage.getItem('Rikosta')
        }
      })
      if (data?.success) {

        getPairs();

      } else {
        toast.error(data?.message)
      }
    } catch (error) {
      console.log("🚀 ~ add fav ~ error:", error)
    }

  }

  // const handleNoOfCoins = async (coins) => {
  //   console.log(coins, "coins");

  //   if (coins == "All") {
  //     getPairs();
  //   } else {

  //     const noCoins = Number(coins);
  //     console.log(noCoins, "coins");

  //     // if( (noCoins > 0)) {

  //     setNumCoins(noCoins);

  //     if (coins == "") {
  //       getPairs();
  //     } else {
  //       getPairs1(noCoins);
  //     }

  //   }

  //   // }

  // };

  // const handleNoOfCoins = async (coins) => {
  //   // console.log(coins, "coinsssuuu");

  //   if (coins == "All") {
  //     getPairs();
  //   } else {
  //     // console.log(coins,"coinscoinscoins");
  //     setNoOfCoins(coins);

  //     try {

  //       let tot = 0;
  //       const filteredData = [];

  //       for (let symbol of rows) {
  //         const highPrice = Number(document.querySelector(`.high-${symbol?.symbol}`)?.textContent);
  //         const lowPrice = Number(document.querySelector(`.low-${symbol?.symbol}`)?.textContent);
  //         const currentprice = Number(document.querySelector(`.price-${symbol?.symbol}`)?.textContent);

  //         const percentageDifference = ((highPrice - lowPrice) / 2 / highPrice) * 100;
  //         const average = (highPrice + lowPrice) / 2;

  //         const da = {
  //           image: symbol?.imageurl || "",
  //           baseCoin: symbol?.baseCoin,
  //           quoteCoin: symbol?.quoteCoin,
  //           close: "0",
  //           symbol: symbol?.symbol,
  //           openprice: "0",
  //           volume: "0",
  //           high: "0",
  //           low: "0",
  //           exchange: "",
  //           status: true,
  //           users_id: symbol?.users_id,
  //         };

  //         // Check percentageDifference and push data if condition is met
  //         if (filteredData.length < coins) {

  //           if (percentageDifference >= 3 && (!below || (below && currentprice <= average))) {
  //             const exists = filteredData.some(item => item?.symbol === symbol?.symbol);

  //             if (!exists) {
  //               filteredData.push(da);
  //               tot++;
  //             } else if (exists) {
  //               // Update status if symbol exists in filteredData
  //               filteredData.map(item =>
  //                 item.symbol === symbol?.symbol ? { ...item, status: true } : item
  //               );
  //             }
  //           }
  //         }
  //       }

  //       // Set state based on the filtered data
  //       setBestprice(filteredData);

  //     } catch (error) {
  //       console.error("Error in handleNoOfCoins:", error);
  //     }

  //   }

  // };

  // const checkBelow = async () => {
  //   try {
  //     bestprice?.forEach((symbol) => {
  //       const highPrice = Number(document.querySelector(`.high-${symbol?.symbol}`)?.textContent);
  //       const lowPrice = Number(document.querySelector(`.low-${symbol?.symbol}`)?.textContent);
  //       const Price = Number(document.querySelector(`.price-${symbol?.symbol}`)?.textContent);

  //       const percentageDifference = (highPrice + lowPrice) / 2
  //       setBestprice(prevItems => {
  //         if (percentageDifference >= Price) {
  //           return prevItems.map(item =>
  //             item.symbol === symbol?.symbol ? { ...item, status: true } : item
  //           );
  //         } else {
  //           return prevItems.map(item =>
  //             item.symbol === symbol?.symbol ? { ...item, status: false } : item
  //           );
  //         }
  //       });
  //     })
  //   } catch (error) {
  //     console.log("🚀 ~ checkBelow ~ error:", error)
  //   }
  // }

  // useEffect(() => {
  //   console.log(below, "below");

  //   let timer; // Declare timer outside the if statement

  //   if (below) {
  //     console.log(below, "below");
  //     timer = setInterval(() => {
  //       // Your interval logic here
  //       checkBelow()
  //     }, 6000);
  //   }

  //   // Cleanup function
  //   return () => {
  //     if (timer) {
  //       clearInterval(timer); // Clear the interval
  //     }
  //   };
  // }, [below]);

  const habdlePopup = async (row) => {

    // console.log(row, "wwwwww");
    setRowData(row);
    setOpen(true);

  };

  const [btnLog, setBtnLog] = useState([
    { name: 'Binance', img: Support1 },
    { name: 'Bybit', img: Support2 },
    { name: 'Okx', img: Support3 },
    { name: 'Mexc', img: Support5 },
  ])


  return (
    <div className="market-full" style={{ paddingBottom: "50px" }}>
      <Header />
      <div className="contain-width">



        <div className="slider-container">
          <Slider {...settings}>
            {
              hotCoins?.map((item, index) => (
                <div className="trade-coin display-1 md-flex">
                  <div className="coin-img">
                    <img src={BTC} alt="btc" />
                  </div>
                  <div className="coin-img">
                    <p className="display-1 md-flex">
                      {item?.symbol}{<span className={`pricech-${item?.symbol} red-bg`}>{item?.priceChangePercent ? item?.priceChangePercent : "0"}</span>}  {/* price change percentage */}
                    </p>
                    {/* {datatusd.c === undefined ? <p> 0 </p> : <p className={`.price1-${item?.symbol} green-bg`} >{parseFloat(datatusd?.c).toFixed(4)}</p>}  last price */}
                    {/* {datatusd.x === undefined ? <p> 0 </p> : <p>{parseFloat(datatusd?.x).toFixed(4)}</p>} first trade price before 24hr */}
                    <p className={`close-${item?.symbol} red-bg`}>{item?.lowPrice ? item?.lowPrice : "0"}</p>
                    <p className={`xtra-${item?.symbol} red-bg`}>{item?.highPrice ? item?.highPrice : "0"}</p>
                    {/* <p>36,641.20</p> */}
                  </div>
                  <div>
                    {/* <SparkLineChart
                data={[1, 1, 2, 5, 7, 2, 4, 6, 5, 5, 2]}
                height={100}
              /> */}
                    {/* <div style={{ display:}}></div> */}
                    <div ></div>
                    {/* {datatusd.P > 0 ? <img src={Green} alt="green-chart" /> : <img src={Red} alt="red-chart" />} */}
                    {console.log(item?.priceChangePercent, 'value')}
                    {item?.highPrice >= '0' ? <img src={Green} alt="green-chart" /> : <img src={Red} alt="red-chart" />}
                  </div>
                </div>
              ))
            }

          </Slider>
        </div>







        <div className="search-list display-2">
          {/* <div className="search-bar display-1">
            <TextField placeholder="No Of Tokens" type="number" style={{ color: 'white' }} onChange={(e) => {
              handleNoOfCoins(e.target.value)
            }} />
          </div> */}
          <div>
            {/* <p>24hr Change</p> */}
          </div>
          <div className="select-part display-1">



            <div className="search-bar display-1">
              <SearchIcon />
              <TextField placeholder="Search" onChange={(e) => {
                setSearch(e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase());
                setPage(1)
              }} />
            </div>
          </div>
        </div>
        <div className="display-2 search-list mt-20">
          <div style={{ display: 'flex', gap: '10px', flexFlow: 'wrap' }} >
            {btnLog.map((option, i) => (
              <button
                key={
                  i
                }
                onClick={() => { setOldExchange(exchange); setBelow(false); handleOpenBackdrop(); setExchange(option); setPage(1); }}
                style={{
                  padding: '10px 20px',
                  backgroundColor: exchange === option ? '#10d6ba' : '#e0e0e0',
                  color: '#000',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontWeight: exchange === option ? 'bold' : '600'
                }}
              >
                <div className="display-1">

                  <img src={option.img} alt={option.img} width={25} height={25} />

                  {option?.name.charAt(0).toUpperCase() + option?.name.slice(1)}
                </div>

              </button>
            ))}
          </div>
          <div className="display-1 select-part">
            <FormControlLabel control={<Checkbox style={{ color: "rgba(20, 221, 182, 1)" }} checked={below} onChange={() => { setBelow(!below); handleOpenBackdrop(); }} />} label="Below Average" />
            <FormControlLabel control={<Checkbox style={{ color: "rgba(20, 221, 182, 1)" }} checked={fav} onChange={() => { GetFavTokens(!fav) }} />} label="Favourites" />



            <Select native id="grouped-native-select" onChange={(e) => { handleOptionChange(e) }}>
              <optgroup >
                <option value={'USDT'}>USDT</option>
                <option value={'BTC'}>BTC</option>
                <option value={'ETH'}>ETH</option>
                <option value={'USDC'}>USDC</option>
                {/* <option value={"EUR"}>EUR</option> */}
                {/* <option value={"DAI"}>DAI</option> */}
              </optgroup>
            </Select>

            <Select native id="grouped-native-select" onChange={(e) => {
              // setPerPage(e.target.value);
              // handleNoOfCoins(e.target.value)
              // setPage(1)
              setPerPage(e.target.value)
            }}>
              <optgroup >
                {/* <option value={'10'}>All</option> */}
                <option value={'10'}>10</option>
                {/* <option value={'2'}>2</option> */}
                {/* <option value={'5'}>5</option> */}
                <option value={'8'}>15</option>
                <option value={'20'}>20</option>
                {/* <option value={'30'}>30</option> */}
                {/* <option value={"EUR"}>EUR</option> */}
                {/* <option value={"DAI"}>DAI</option> */}
              </optgroup>
            </Select>
            {/* <Select native id="grouped-native-select" onChange={(e) => { handleSortOption(e) }}>
              <optgroup

              >
                <option value={"A"}>A to Z</option>
                <option value={"Z"}>Z to A</option>
              </optgroup>
            </Select> */}
          </div>
        </div>



        {
          load ? <div class="card" style={{ height: '50vh' }}>
            <div class="loader">
              <p>Fetching..!</p>
              <div class="words">
                <span class="word display-1">
                  <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/btc.svg" alt="bitcoin-exchange" />
                  BTC
                </span>
                <span class="word display-1">
                  <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/usdt.svg" alt="bitcoin-exchange" />
                  USDT

                </span>
                <span class="word display-1">
                  <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/eth.svg" alt="bitcoin-exchange" />
                  ETH

                </span>
                <span class="word display-1">
                  <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/xrp.svg" alt="bitcoin-exchange" />
                  XRP

                </span>
                <span class="word display-1">
                  <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/bnb.svg" alt="bitcoin-exchange" />
                  BNB

                </span>
                {/* <span class="word">High Price</span> */}
              </div>
            </div>
          </div>
            : resstate2 ?
              <>
                <h2 className="text-center">Result Data</h2>
                <div className="custom_table table2">
                  <TableContainer>
                    <Table aria-label="simple table" className="prf-table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Token</TableCell>
                          <TableCell align="center" >Price</TableCell>
                          <TableCell align="center">24h High</TableCell>
                          <TableCell align="center">24h Low </TableCell>
                          <TableCell align="center">Volume</TableCell>
                          {/* <TableCell align="center">Open Price</TableCell> */}
                          <TableCell align="center">Exchange</TableCell>
                          <TableCell align="center">Click & Trade</TableCell>
                          {/* <TableCell align="right">Factor</TableCell>
                  <TableCell align="center">Order Book</TableCell> */}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* {console.log(nocoin,"nocoin",resstate.length)} */}
                        {
                          // resstate?.length > 0 ?
                          //   !changePage && resstate && [...resstate].slice(0, Number(nocoin)).map((row, index) => (
                          bestprice?.length > 0 ?
                            // !changePage && bestprice && [...bestprice].slice(0, Number(nocoin)).map((row, index) => (
                            // !changePage && bestprice.sort((a, b) => sortOption === 'A'
                            //   ? a.symbol.localeCompare(b.symbol) // Sort A to Z
                            //   : b.symbol.localeCompare(a.symbol))
                            // .slice(0, Number(nocoin))
                            // .slice((page - 1) * perPage, page * perPage)
                            bestprice.map((row, index) => (
                              row?.status == true ? (
                                <TableRow
                                  key={index}
                                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                  <TableCell align="center">
                                    <div className="token_flx">
                                      <Avatar sx={{ width: 48, height: 48 }} src={row?.image} />
                                      <div>
                                        {/* <p>{row.token}</p> */}
                                        <p>{row?.symbol}</p>

                                        {/* <span className="grey">{row.token_short}</span> */}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center"  >
                                    <p className={`price-${row?.symbol}`}></p>
                                    {/* <p>{row?.close ? row?.close : '0'}</p> */}
                                    {/* <p>{row.price_h}</p> */}
                                  </TableCell>

                                  <TableCell align="center"   >
                                    <p className={`high-${row?.symbol}`}></p>
                                    {/* {row?.low ? row?.low : '0'} */}
                                  </TableCell>

                                  <TableCell align="center"   >
                                    <p className={`low-${row?.symbol}`}></p>
                                    {/* {row?.high ? row?.high : '0'} */}
                                  </TableCell>
                                  <TableCell align="center" >
                                    <p className={`vol-${row?.symbol}`}></p>
                                    {/* {row?.volume ? row?.volume : '0'} */}
                                    {/* <p className="green">{row.volume} </p> */}
                                  </TableCell>

                                  {/* <TableCell align="center" >
                                    <p className={`open-${row?.symbol}`} ></p>
                                    {row?.openprice ? row?.openprice : '0'}
                                  </TableCell> */}


                                  <TableCell align="center"   >
                                    {row?.exchange ? row?.exchange.toUpperCase() : ' '}
                                  </TableCell>



                                  {/* <TableCell align="right">

                                    <div className="flx">

                                      <img src={row.chart_icon} /> <p>{row.factor} </p>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">

                                    <p>{row.order} </p>{" "}
                                    <p className="green"> {row.order_data}</p>{" "}
                                  </TableCell> */}

                                  < TableCell align="center" className="cursor" >
                                    <img src={star} onClick={() => handleOpen(row)} />
                                  </TableCell>
                                </TableRow>
                              ) : null
                            ))


                            :
                            <TableRow
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell align="center">{'No Data Found'}</TableCell>
                            </TableRow>
                        }

                        {
                          changePage &&
                          <TableRow>
                            <TableCell colSpan={8} align="center">
                              <div class="card">
                                <div class="loader">
                                  <p>Fetching..!</p>
                                  <div class="words">
                                    <span class="word display-1">
                                      <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/btc.svg" alt="bitcoin-exchange" />
                                      BTC
                                    </span>
                                    <span class="word display-1">
                                      <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/usdt.svg" alt="bitcoin-exchange" />
                                      USDT

                                    </span>
                                    <span class="word display-1">
                                      <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/eth.svg" alt="bitcoin-exchange" />
                                      ETH

                                    </span>
                                    <span class="word display-1">
                                      <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/xrp.svg" alt="bitcoin-exchange" />
                                      XRP

                                    </span>
                                    <span class="word display-1">
                                      <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/bnb.svg" alt="bitcoin-exchange" />
                                      BNB

                                    </span>
                                    {/* <span class="word">High Price</span> */}
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        }

                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="model-market"
                  >
                    <Slide in={open} direction="down" timeout={500}>
                      <Box sx={style}>
                        <div className="text-right">
                          <CancelOutlinedIcon sx={{ fill: 'var(--clr-font1)' }} className="cursor" onClick={handleClose} />
                        </div>
                        <div className="mt-20">
                          <div className="display-4" style={{ gap: "80px" }}>
                            <img src={star} alt="star" />

                            <div className="tr-now">
                              <Button variant="contained" onClick={handleOpen2}>
                                Trade Now
                              </Button>
                            </div>
                          </div>
                          <div className="mt-20" >
                            <div className="display-2" style={{ flexFlow: 'wrap' }}>
                              <div className="display-1">
                                <img src={btc} alt="btc" className="coin" />
                                <div className="c-name">
                                  BTC #1
                                  <div className="c-inner">
                                    BTC
                                  </div>
                                </div>
                              </div>
                              <div className="c-price">
                                USD WAARDE
                                <div className="c-price-inner">
                                  $0.374
                                </div>
                              </div>
                              <div className="c-price">
                                VERANDERING 24H
                                <div className="c-price-inner">
                                  $0.374
                                </div>
                              </div>
                              <div className="c-price">
                                BTC24H VOLUME
                                <div className="c-price-inner">
                                  $0.374
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="" style={{ marginTop: "50px" }}>
                            <Grid2 container spacing={3}>

                              <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                                <div className="in-detail">
                                  <div className="in-head">
                                    24K HIGH
                                  </div>
                                  <div className="mt-10 in-para">
                                    0.00001256
                                  </div>
                                </div>
                              </Grid2>
                              <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                                <div className="in-detail">
                                  <div className="in-head">
                                    24H LONG
                                  </div>
                                  <div className="mt-10 in-para">
                                    0.00001256
                                  </div>
                                </div>
                              </Grid2>
                              <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                                <div className="in-detail">
                                  <div className="in-head">
                                    BTC HUIDIGE PRIJS
                                  </div>
                                  <div className="mt-10 in-para">
                                    0.00001256
                                  </div>
                                </div>
                              </Grid2>
                              <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                                <div className="in-detail">
                                  <div className="in-head">
                                    BTC GEMIDDELDE 24H
                                  </div>
                                  <div className="mt-10 in-para">
                                    0.00001256
                                  </div>
                                </div>
                              </Grid2>

                              {/* <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                            <div className="in-detail">
                              <div className="in-head">
                                MARKET CAP
                              </div>
                              <div className="mt-10 in-para">
                                0.00001256
                              </div>
                            </div>
                          </Grid2>
                          <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                            <div className="in-detail">
                              <div className="in-head">
                                SUPPLY
                              </div>
                              <div className="mt-10 in-para">
                                0.00001256
                              </div>
                            </div>
                          </Grid2>
                          <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                            <div className="in-detail">
                              <div className="in-head">
                                MAX SUPPLY
                              </div>
                              <div className="mt-10 in-para">
                                0.00001256
                              </div>
                            </div>
                          </Grid2>
                          <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                            <div className="in-detail">
                              <div className="in-head">
                                TOTAL VOLUME
                              </div>
                              <div className="mt-10 in-para">
                                0.00001256
                              </div>
                            </div>
                          </Grid2> */}

                            </Grid2>
                          </div>
                        </div>
                      </Box>
                    </Slide>
                  </Modal>

                  <Modal
                    open={open2}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="model-market"
                  >
                    <Box sx={style2}>
                      <div className="text-right">
                        <CancelOutlinedIcon className="cursor" sx={{ fill: 'var(--clr-font1)' }} onClick={handleClose2} />
                      </div>
                      <div className="mt-20">
                        <Stack spacing={2}>
                          {/* <div className="exchnage-logo">
                    <img src={bnc} alt="bnc" />
                  </div>
                  <div className="exchnage-logo">
                    <img src={okx} alt="okx" />
                  </div>
                  <div className="exchnage-logo">
                    <img src={bybit} alt="bybit" />
                  </div> */}
                        </Stack>
                      </div>
                    </Box>
                  </Modal>
                </div> </> :

              <div className="custom_table">
                <TableContainer>
                  <Table aria-label="simple table" className="prf-table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" >Favourite</TableCell>
                        <TableCell align="center" onClick={() => { handleSortOption(sortOption) }} >
                          <div className="display-1">
                            Token
                            <Tooltip title={sortOption === 'A' ? 'A-Z' : 'Z-A'} placement="right-start">
                              <div
                                className="display-1"

                              >
                                {sortOption == 'A' ? <ArrowDropDownIcon className="cursor" /> :
                                  <ArrowDropUpIcon className="cursor" />}
                              </div>
                            </Tooltip>

                          </div>

                        </TableCell>
                        <TableCell align="center" >Price</TableCell>
                        <TableCell align="center">24h High</TableCell>
                        <TableCell align="center">24h Low</TableCell>
                        <TableCell align="center">Average</TableCell>
                        <TableCell align="center">Volume</TableCell>
                        {/* <TableCell align="center">Avg</TableCell> */}
                        <TableCell align="center">Exchange</TableCell>
                        {/* <TableCell align="right">Factor</TableCell>
                  <TableCell align="center">Order Book</TableCell> */}
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* { console.log(bestprice,bestprice.length, "priceeeu") } */}
                      {/* { console.log(perPage,page, "perPage") } */}
                      {
                        bestprice?.length > 0 ?
                          // bestprice && [...bestprice].slice((page - 1) * perPage, page * perPage).map((row, index) =>
                          // bestprice && bestprice?.map((row, index) =>
                          bestprice && (
                            [...bestprice]
                              .sort((a, b) =>
                                sortOption === 'A'
                                  ? a.symbol.localeCompare(b.symbol) // Sort A to Z
                                  : b.symbol.localeCompare(a.symbol))
                              .slice((page - 1) * perPage, page * perPage)
                              // .slice(0, numCoins > 0 ? numCoins : bestprice.length)
                              .map((row, index) =>
                                <TableRow
                                  key={index}
                                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                  style={row?.status == true ? {} : { display: 'none' }}
                                >
                                  <TableCell>

                                    <div style={{ cursor: 'pointer' }} className="text-center" onClick={() => handleFav(row?.symbol)}>
                                      {
                                        row?.users_id && row?.users_id != undefined ?

                                          (row?.users_id.includes(userID) ? <StarIcon style={{ color: 'gold' }} /> :
                                            <StarBorderIcon style={{ color: 'gold' }} />
                                          ) : null

                                      }
                                    </div>

                                  </TableCell>
                                  <TableCell align="center">
                                    <div className="token_flx">
                                      {/* <div style={{ cursor: 'pointer' }} onClick={() => handleFav(row?.symbol)}>
                                    {
                                      row?.users_id && row?.users_id != undefined ?

                                        (row?.users_id.includes(userID) ? <StarIcon style={{ color: 'gold' }} /> :
                                          <StarBorderIcon style={{ color: 'gold' }} />
                                        ) : null

                                    }
                                  </div> */}
                                      {!row?.image ? <Avatar sx={{ width: 48, height: 48 }}>{row?.symbol?.split("")[0]}</Avatar> :
                                        <Avatar sx={{ width: 48, height: 48 }} src={row?.image} />}

                                      <div>
                                        {/* <p>{row.token}</p> */}
                                        <p>{row?.symbol}</p>

                                        {/* <span className="grey">{row.token_short}</span> */}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center" className={`price-${row?.symbol}`}>
                                    0
                                    {/* <p>{row?.close ? parseFloat(row?.close).toFixed(4) : '0'}</p> */}
                                    {/* <p>{row.price_h}</p> */}
                                  </TableCell>

                                  <TableCell align="center" className={`high-${row?.symbol}`}  >
                                    0
                                    {/* {row?.high ? parseFloat(row?.high).toFixed(4) : '0'} */}
                                  </TableCell>

                                  <TableCell align="center" className={`low-${row?.symbol}`}  >
                                    0
                                    {/* {row?.low ? parseFloat(row?.low).toFixed(4) : '0'} */}
                                  </TableCell>

                                  <TableCell align="center" className={`avg-${row?.symbol}`}  >
                                    0
                                    {row?.openprice ? parseFloat(row?.openprice).toFixed(4) : '0'}
                                  </TableCell>

                                  <TableCell align="center" className={`vol-${row?.symbol}`} >
                                    0
                                    {/* {row?.volume ? parseFloat(row?.volume).toFixed(4) : '0'} */}
                                    {/* <p className="green">{row.volume} </p> */}
                                  </TableCell>

                                  {/* <TableCell align="center" className={`open-${row?.symbol}`}  >
                              0
                              {row?.openprice ? parseFloat(row?.openprice).toFixed(4) : '0'}
                            </TableCell> */}

                                  {/* <TableCell align="center"  >
                                {row?.avg ? Number(row?.avg).toFixed(2) : '0'}
                              </TableCell> */}

                                  <TableCell align="center" className={`trades-${row?.symbol}`}  >
                                    <Button onClick={() => { habdlePopup(row) }} >

                                      <img width="30" height="30" src="https://img.icons8.com/fluency/30/info.png" alt="info" />
                                    </Button>
                                    {/* {row?.exchange ? row?.exchange.toUpperCase() : 'BINANCE'} */}
                                  </TableCell>
                                  {/* <TableCell align="right">

                      <div className="flx">

                        <img src={row.chart_icon} /> <p>{row.factor} </p>
                      </div>
                    </TableCell>
                    <TableCell align="center">

                      <p>{row.order} </p>{" "}
                      <p className="green"> {row.order_data}</p>{" "}
                    </TableCell>
                  */}
                                  <TableCell className="cursor">
                                    <img src={row?.star} onClick={handleOpen} />
                                  </TableCell>
                                </TableRow>
                              )
                          )
                          :
                          <TableRow
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell align="center">{'No Data Found'}</TableCell>
                          </TableRow>
                      }

                      {
                        changePage &&
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            <div class="card">
                              <div class="loader">
                                <p>Fetching..!</p>
                                <div class="words">
                                  <span class="word display-1">
                                    <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/btc.svg" alt="bitcoin-exchange" />
                                    BTC
                                  </span>
                                  <span class="word display-1">
                                    <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/usdt.svg" alt="bitcoin-exchange" />
                                    USDT

                                  </span>
                                  <span class="word display-1">
                                    <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/eth.svg" alt="bitcoin-exchange" />
                                    ETH

                                  </span>
                                  <span class="word display-1">
                                    <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/xrp.svg" alt="bitcoin-exchange" />
                                    XRP

                                  </span>
                                  <span class="word display-1">
                                    <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/bnb.svg" alt="bitcoin-exchange" />
                                    BNB

                                  </span>
                                  {/* <span class="word">High Price</span> */}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      }

                    </TableBody>
                  </Table>
                </TableContainer>

                <Modal
                  open={open}
                  // onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="model-market"
                >

                  <Box sx={style} className="bg-img">
                    <div className="text-right">
                      <CancelOutlinedIcon className="cursor" onClick={handleClose} />
                    </div>
                    <div className="mt-10"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                      data-aos-duration="1000"
                    >
                      <div className="display-2" style={{ gap: "80px" }}>
                        {/* <img src={star} alt="star" /> */}

                        <div className="display-2" style={{ flexFlow: 'wrap' }}>
                          <div className="display-1">
                            {!rowData?.image ? <Avatar sx={{ width: 48, height: 48 }}>{rowData?.symbol?.split("")[0]}</Avatar>
                              : <Avatar src={rowData?.image ? rowData?.image : btc} alt="btc" className="coin" sx={{ width: 48, height: 48 }} />}
                            <div className="c-name">
                              {/* BTC #1 */}
                              <div className="c-inner">
                                {rowData?.symbol ? rowData?.symbol : ""}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tr-now">
                          {/* <Button variant="contained" onClick={handleOpen2}> */}
                          <Button variant="contained" onClick={() => { handleOpen(rowData) }}>
                            Trade Now
                          </Button>
                        </div>

                      </div>
                      <div className="mt-10" >
                        <div className="" style={{ marginTop: "20px" }}>
                        <Grid2 container spacing={3}>

                            <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                              <div className="in-detail">
                                {/* USD WAARDE */}
                                <div className="in-head">
                                PRICE
                                </div>
                                <div className={`price-${rowData?.symbol} mt-10 in-para`}>
                                  0
                                </div>
                              </div>
                            </Grid2>
                            <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                              <div className="in-detail">
                                {/* VERANDERING 24H */}
                                <div className="in-head">
                                24H CHANGE
                                </div>
                                <div className={`24hc-${rowData?.symbol} mt-10 in-para`}>
                                  0
                                </div>
                              </div>
                            </Grid2>
                            <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                              <div className="in-detail">
                              <div className="in-head">
                                24H VOLUME
                                </div>
                                <div className={`vol-${rowData?.symbol} mt-10 in-para`}>
                                  $0.374
                                </div>
                              </div>
                            </Grid2>
                          </Grid2>
                          </div>

                      </div>
                      <div className="" style={{ marginTop: "10px" }}>
                        <Grid2 container spacing={3}>

                          <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                            <div className="in-detail">
                              <div className="in-head">
                                24h HIGH
                              </div>
                              <div className={`high-${rowData?.symbol} mt-10 in-para`}>
                                0
                              </div>
                            </div>
                          </Grid2>
                          {/* <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                            <div className="in-detail">
                              <div className="in-head">
                                24H OPEN
                              </div>
                              <div className={`open-${rowData?.symbol} mt-10 in-para`}>
                                0
                              </div>
                            </div>
                          </Grid2> */}
                          <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                            <div className="in-detail">
                              <div className="in-head">
                                24H LOW
                              </div>
                              <div className={`low-${rowData?.symbol} mt-10 in-para`}>
                                0
                              </div>
                            </div>
                          </Grid2>
                          <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                            <div className="in-detail">
                              <div className="in-head">
                                AVERAGE 24H
                              </div>
                              <div className={`avg-${rowData?.symbol} mt-10 in-para`}>
                                -
                              </div>
                            </div>
                          </Grid2>
                          {/* <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              MARKET CAP
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2> */}
                          {/* <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              SUPPLY
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              MAX SUPPLY
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              TOTAL VOLUME
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2> */}

                        </Grid2>
                      </div>

                      <div className="pop-chart mt-20">
                  <div className="display-2" style={{ flexFlow: 'wrap', gap: '10px' }} >
                    <div className="c-name">
                      7D GRAFIEK
                    </div>
                    <div className="c-name">
                      24H VOLALITEIT <span style={{ color: "var(--clr-font1)" }}>19.2%</span>
                    </div>
                    <div className="c-name">
                      FACTOR <span style={{ color: "var(--clr-font1)" }}>9.59</span>
                    </div>
                  </div>
                  <div>
                    <img src={vector} alt="red-chart" style={{width:"100%"}}/>
                  </div>
                </div>
                    </div>
                  </Box>

                </Modal>

                <Modal
                  open={open2}
                  // onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="model-market"
                >
                  <Box sx={style2}>
                    <div className="text-right">
                      <CancelOutlinedIcon className="cursor" onClick={handleClose2} />
                    </div>
                    <div className="mt-20">
                      <Stack spacing={2}>
                        {/* <div className="exchnage-logo">
                    <img src={bnc} alt="bnc" />
                  </div>
                  <div className="exchnage-logo">
                    <img src={okx} alt="okx" />
                  </div>
                  <div className="exchnage-logo">
                    <img src={bybit} alt="bybit" />
                  </div> */}


                      </Stack>
                    </div>
                  </Box>
                </Modal>

                <div className="view-all text-center" style={{ paddingBottom: "20px" }}>
                  {/* <Link to="">View all digital assets <ArrowForwardIcon /></Link> */}
                  <Pagination count={Math.ceil(bestprice?.length / perPage)} page={page} onChange={handleChange1} shape="rounded" />
                </div>
              </div>
        }

        {
          rows.length > 0 &&
          rows?.map((row, index) => {

            return (

              <div key={index}>

                {/* binance datas */}
                <span style={{ display: 'none' }} className={`price-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`open-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`vol-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`high-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`low-${row?.symbol}`}></span>

                {/* <span style={{ display: 'none' }} className={`byb-price-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`byb-open-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`byb-vol-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`byb-high-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`byb-low-${row?.symbol}`}></span>

                <span style={{ display: 'none' }} className={`okx-price-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`okx-open-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`okx-vol-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`okx-high-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`okx-low-${row?.symbol}`}></span>

                <span style={{ display: 'none' }} className={`mex-price-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`mex-open-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`mex-vol-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`mex-high-${row?.symbol}`}></span>
                <span style={{ display: 'none' }} className={`mex-low-${row?.symbol}`}></span> */}
              </div>

            );
          })
        }

      </div >
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, backdropFilter: 'blur(10px)' })}
        open={backDrop}
      // onClick={handleCloseBackdrop}
      >
        {/* <CircularProgress color="inherit" /> */}


        {/* <div class="loaderx2">
          <span>Clicks & Trades</span>
          <span>Clicks & Trades</span>
        </div> */}

        <div class="center-body">
          <div class="loader-circle-9">
            <img src={favicon} alt="log" style={{ marginTop: "8px" }} />
            <span></span>
          </div>
        </div>
      </Backdrop>
    </div >
  );
};


export default Market;
