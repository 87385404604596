import React from "react";
import { Navigate, useLocation } from "react-router-dom";


function PrivateRoute({ children }) {

    const navigateToLogin = () => {

        return (
            <>
                <Navigate
                    to={'/login'}
                />
            </>
        );
    };


    return localStorage.getItem("Rikosta") !== null ? children : navigateToLogin();
}

export default PrivateRoute;
