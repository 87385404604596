import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';

import InputAdornment from '@mui/material/InputAdornment';
import './Login/Login.css'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from "../../Images/logo.webp";
import { useNavigate, NavLink } from 'react-router-dom';
import Axios from '../Axios.js';
import { Button } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';


const Resend = () => {
    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate()


    const [emailError, setEmailError] = useState("")
    const [email, setEmail] = useState("")
    const [status, setStatus] = useState(false)

    const checkemail = (e) => {
        if (!regex.test(e)) {
            setEmailError("Invalid Email Format")
        } else {
            setEmailError("")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email === "") {
            toast.error("Please Enter  Email");
            return;
        }

        try {
            setStatus(true);
            const { data } = await Axios.post('/re_send', {
                email: email
            })
            console.log(data, "vijay");

            if (data?.success === true) {
                window.localStorage.setItem("email", email)
                toast.success("OTP Sent To Your email");
                setEmail("")
                setTimeout(() => {
                    navigate(`/verify`);
                }, 100);
            }
            else {
                toast.error(data?.message);
                setEmail("")
            }

        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.error(error);
            setStatus(false);
        }
    };

    return (
        <div className={mdScreen && 'auth-page'} style={{ height: '100vh', overflow: mdScreen && 'hidden' }}>

            <Grid container spacing={0} style={{ height: '100%' }} alignItems={'center'} justifyContent={!mdScreen && 'center'}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className="login-left-part">
                        <div className="logo-part">
                            <Link to='/'>
                                <img src={Logo} alt="click&trade" style={{ width: '250px' }} className='log-logo' />
                            </Link>
                        </div>

                        <div className='login-grid'>
                            <div className="auth-form">
                                <div className="twofa-title text-center">
                                    Resend Verification Email
                                </div>

                                {/* <div className="register-acctn">
                                    <p className='reg-free'>We have sent you an verification code to your email.</p>
                                </div> */}
                                <div className='email-filed'>
                                    {/* <label className='display-1 fllname'>Email</label> */}
                                    <TextField placeholder='Enter Your Email'
                                        value={email}
                                        onChange={(e) => { setEmail(e.target.value); checkemail(e.target.value) }}
                                        slotProps={{
                                            input: {
                                                startAdornment: <InputAdornment position="start">
                                                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.65 7.12915C13.4625 7.12915 14.15 7.81665 14.15 8.62915V14.6292C14.15 15.4729 13.4625 16.1292 12.65 16.1292H1.65004C0.80629 16.1292 0.15004 15.4729 0.15004 14.6292V8.62915C0.15004 7.81665 0.80629 7.12915 1.65004 7.12915H2.40004V4.87915C2.40004 2.2854 4.52504 0.12915 7.15004 0.12915C9.74379 0.12915 11.9 2.2854 11.9 4.87915V7.12915H12.65ZM9.40004 7.12915V4.87915C9.40004 3.6604 8.36879 2.62915 7.15004 2.62915C5.90004 2.62915 4.90004 3.6604 4.90004 4.87915V7.12915H9.40004Z" fill="#15BD6F" />
                                                    </svg>

                                                </InputAdornment>,
                                            },
                                        }}
                                        type='emial'

                                    />
                                    <div className="err-div mt-10">{emailError ? <p style={{ color: "red" }}>{emailError}</p> : ""}</div>
                                </div>




                                <div className='form-submit margin-t-30px'>
                                    <Button onClick={handleSubmit} >Resend Otp</Button>
                                </div>

                                <div className="register-acctn">
                                    <p className='reg-free'>Don’t have an account yet?  <NavLink className='reg-free' to='/register'>Register for free</NavLink></p>
                                </div>
                            </div>

                        </div>

                    </div>
                </Grid>

                {mdScreen && <Grid item xs={12} sm={12} md={6} lg={8} xl={8}></Grid>}

            </Grid>
            <Toaster />

        </div>
    )
}

export default Resend
