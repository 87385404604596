import React, { useEffect, useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../../Images/headlogo1.webp'
import logoWhite from '../../Images/lightlogo.webp'
import './Header.css'
import Stack from '@mui/material/Stack';
import DarkMode from '../DarkMode/DarkMode';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import userlogo from '../../Images/userlogo.webp'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Menu from '@mui/material/Menu';
import { useNavigate, NavLink } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Axios from '../Axios';
import logout from '../Logout'
import { ThemeContext } from '../DarkMode/ThemeProvider';
import consts from '../../constant';
import Popover from '@mui/material/Popover';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Web3 from 'web3';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SmsIcon from '@mui/icons-material/Sms';
import { useWeb3Modal } from '@web3modal/ethers/react'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';

const drawerWidth = 240;

const projectId = '5dccf6c27090e907e7f26ce288dd6689'

function DrawerAppBar({ renderComp, value, user }) {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [token, setToken] = useState("")


    // const { open } = useWeb3Modal();
    // const { isConnected, address } = useAccount();
    // const { disconnect } = useDisconnect();

    const { open } = useWeb3Modal()

    const { address, chainId, isConnected } = useWeb3ModalAccount()

    // const [provider, setProvider] = useState("")

    const { isLightMode, handleDarkmode } = useContext(ThemeContext);

    const [anchorEl2, setAnchorEl2] = React.useState(null);

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setOutterNotification(0)
        getPriceAlerts()
        getNotification()
        setPriceAlrt(false)
        setSystemMsg(false)
        setAnchorEl2(null);
    };

    // const connectWallet = async () => {
    //     const providers = await open()
    //     console.log("🚀 ~ connectWal ~ providers:", providers)
    // }

    const openPop2 = Boolean(anchorEl2);
    const id2 = openPop2 ? 'simple-popover' : undefined;

    const [routes, setRoutes] = useState([
        { name: 'Markets', path: `/market` },
    ]);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const [anchorEl, setAnchorEl] = useState(null);
    const [notifyCount, setNotifyCount] = useState(0);
    const [notifyData, setNotifyData] = useState([])
    const [userData, setUserData] = useState({})
    const [priceAlerts, setPriceAlerts] = useState([]);
    const [alertCount, setAlertCount] = useState(0);
    const [outterNotification, setOutterNotification] = useState(0);
    const [alertIds, setAlertIds] = useState([]);
    const [notificationIds, setNotificationIds] = useState([]);

    const navigate = useNavigate()

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const [age, setAge] = useState(10);
    const [walletadd, setWalletadd] = useState("")
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [systemMsg, setSystemMsg] = useState(false)

    const [priceAlrt, setPriceAlrt] = useState(false)

    const connectwallet = async () => {
        if (window.ethereum) {
            try {
                const addressArray = await window.ethereum.request({
                    method: "eth_requestAccounts"
                });
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: consts.chainId }], // chainId must be in hexadecimal numbers
                });
                window.localStorage.setItem("Address", addressArray[0])
                setWalletadd(addressArray[0]);
                // setMetamaskadd(addressArray[0]);
                // const obj = {
                //   status: "👆🏽 Write a message in the text-field above.",
                //   address: addressArray[0],
                // };
            } catch (err) {
                return {
                    address: "",
                    status: "😥 " + err.message,
                };
            }
        } else {
            setTimeout(() => {
                toast.error("Please Install Wallet", {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                })
            }, 100);
            window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn')
        }
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <div className="logo-h cursor side">
                {isLightMode ? <img src={logoWhite} alt="l-logo" onClick={() => navigate(`/`)} /> : <img src={logo} alt="d-logo" onClick={() => navigate(`/`)} />
                }

            </div>
            <Divider />
            <List className='lists nav-stack'>
                {routes.map((item, i) => (
                    <Button key={item} sx={{ color: 'var(--clr-font1)' }} onClick={() => navigate(item.path)}>
                        {item.name}  {i === 1 && <Chip label="Euro" className='bagde' size="small" />}
                    </Button>
                ))}
            </List>
        </Box>
    );



    const handleLogout = async () => {

        try {
            const payload = {
                time: new Date().getTime()
            }
            const { data } = await Axios.post('/createLogoutSession', payload, {
                headers: {
                    Authorization: localStorage.getItem('Rikosta')
                }
            })
            if (data.success) {
                handleClose();
                toast.success('Logged out successfully')
                const timer = setTimeout(() => {
                    window.localStorage.removeItem('Rikosta')
                    window.localStorage.removeItem('user')
                    window.localStorage.removeItem("Address")
                    navigate(`/login`)
                }, 1000)
                return () => clearTimeout(timer)
            }

        } catch (error) {
            console.log(error, 'err');

        }
    }

    const [isActive, setIsActive] = useState(true);
    const redirectPath = '/login'
    const timeoutSeconds = 10 * 60 * 1000


    useEffect(() => {
        screenInactive()
    }, [timeoutSeconds, isActive, navigate, redirectPath]);

    const screenInactive = () => {
        let timeoutId;

        const resetTimeout = () => {
            clearTimeout(timeoutId);
            if (isActive) {
                timeoutId = setTimeout(async () => {
                    const session = await logout()
                    if (session) {
                        window.localStorage.removeItem('Rikosta')
                        navigate(`/login`)
                    }
                }, timeoutSeconds);
            }
        };

        const handleMouseActivity = () => {
            setIsActive(true);
            resetTimeout();
        };


        resetTimeout();


        window.addEventListener('mousemove', handleMouseActivity);
        window.addEventListener('keydown', handleMouseActivity);


        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleMouseActivity);
            window.removeEventListener('keydown', handleMouseActivity);
        };
    }


    const getNotification = async () => {
        try {
            const { data } = await Axios.get('/support/getNotification', {
                headers: {
                    Authorization: window.localStorage.getItem('Rikosta')
                }
            })
            if (data?.success) {
                setNotifyCount(data?.result?.length)
                setOutterNotification(prevCount => prevCount + (data?.result?.length))
                setNotifyData(data?.result)
                setNotificationIds(data?.records)
            }
            else {
                setNotifyCount(0)
                setNotifyData([])
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const seenMessage = async (id) => {
        try {
            const { data } = await Axios.post('/support/seenMessage', { id: notificationIds }, {
                headers: {
                    Authorization: window.localStorage.getItem('Rikosta')
                }
            })
            if (data.success) {
                // getNotification()
                setOutterNotification(prevCount => prevCount - (notificationIds?.length))
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const getProfile = async () => {
        try {
            const { data } = await Axios.get('/getProfile', {
                headers: {
                    Authorization: localStorage.getItem('Rikosta')
                }
            })
            if (data.success) {
                setUserData(data.result)
            }
            else {
                setUserData({})
            }
        } catch (error) {
            console.log(error, 'er');

        }
    }

    useEffect(() => {
        setToken(window.localStorage.getItem('Rikosta'))
        if (window.localStorage.getItem('Rikosta')) {
            // getNotification()
            getProfile()
            console.log(token, 'token get profile');

        }
    }, [value])

    const getPriceAlerts = async () => {
        try {
            const { data } = await Axios.get('/getalarm', {
                headers: {
                    Authorization: localStorage.getItem('Rikosta')
                }
            })
            // console.log(data,"deijueoiwfjeuoiwf");
            if (data.success) {
                setOutterNotification(prevCount => prevCount + (data?.result?.length))
                setPriceAlerts(data?.result)
                setAlertCount(data?.result?.length)
                setAlertIds(data?.records)
            }

        } catch (error) {
            console.log(error, 'err');
        }
    }

    useEffect(() => {
        getNotification()
        getPriceAlerts()
    }, [])

    const setPriceAlertsAsSeen = async () => {
        try {
            const { data } = await Axios.post('/seenalarm', { id: alertIds }, {
                headers: {
                    Authorization: localStorage.getItem('Rikosta')
                }
            })

            if (data?.success) {
                setOutterNotification(prevCount => prevCount - (alertIds?.length))
                // getPriceAlerts()
                console.log(data?.message);
            } else {
                console.log(data?.message);
            }

        } catch (error) {
            console.log(error, 'err');
        }
    }

    const handlePriceAlertSeen = async () => {
        if (alertIds.length > 0) {
            setPriceAlertsAsSeen();
        }
    };

    const handleNotificationSeen = async () => {
        if (notificationIds.length > 0) {
            seenMessage();
        }
    };


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" className='app-bg'>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { lg: 'none' } }}
                    >
                        <MenuIcon sx={{ fill: 'var(--clr-font1)' }} />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        classname="display-1"
                        sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' }, gap: '10px' }}
                    >
                        <div className="logo-h cursor">
                            {isLightMode ? <img src={logoWhite} alt="l-logo" onClick={() => navigate(`/`)} /> : <img src={logo} alt="d-logo" onClick={() => navigate(`/`)} />
                            }

                        </div>
                        <Stack direction="row" spacing={3} className='nav-stack'>
                            {routes?.map((item, i) => (
                                <Button key={item} sx={{ color: 'var(--clr-font1)' }} onClick={() => { navigate(item.path) }}>
                                    {item.name}  {i === 1 && <Chip label="Euro" className='bagde' size="small" />}
                                </Button>
                            ))}
                        </Stack>
                    </Typography>
                    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <Stack direction="row" spacing={4} sx={{ alignItems: 'center' }}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    {/* <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        // label="Age"
                                        className='hd-select'
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>ET/BTC</MenuItem>
                                    </Select> */}
                                </FormControl>
                            </Box>
                            {/* <div>
                                <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.36104 13L8.37611 18.9096L14.0886 11H8.6392L9.62414 5.09038L3.91163 13H9.36104ZM12.2985 1.20959C12.4723 0.166948 11.1203 -0.397207 10.5015 0.459698L1.1452 13.4145C0.667572 14.0758 1.14011 15 1.95588 15H7.00012L5.70172 22.7904C5.52795 23.8331 6.87992 24.3972 7.49879 23.5403L16.855 10.5855C17.3327 9.92416 16.8601 9 16.0444 9H11.0001L12.2985 1.20959Z" fill="#777E91" />
                                </svg>
                            </div> */}
                            <Badge className='bagde2' color="secondary" badgeContent={outterNotification}
                            // variant="dot" 
                            >
                            <Tooltip title="Notification" placement="top">
                                <div className="" onClick={(e) => { handleClick2(e); }} style={{ cursor: "pointer" }}>
                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 15.0233C18 15.5113 17.6043 15.907 17.1163 15.907H0.88372C0.395655 15.907 0 15.5113 0 15.0233C0 14.5352 0.395656 14.1395 0.883721 14.1395H0.9V7.98088C0.9 3.57288 4.527 0 9 0C13.473 0 17.1 3.57288 17.1 7.98088V14.1395H17.1163C17.6043 14.1395 18 14.5352 18 15.0233ZM2.7 14.1395H15.3V7.98088C15.3 4.5494 12.4794 1.76744 9 1.76744C5.5206 1.76744 2.7 4.5494 2.7 7.98088V14.1395ZM6.97604 17.7558C6.73121 17.2608 7.19772 16.7907 7.75 16.7907H10.25C10.8023 16.7907 11.2688 17.2608 11.024 17.7558C10.9155 17.9751 10.7699 18.1773 10.591 18.3529C10.169 18.7672 9.59674 19 9 19C8.40326 19 7.83097 18.7672 7.40901 18.3529C7.23013 18.1773 7.08449 17.9751 6.97604 17.7558Z" fill="#777E91" />
                                    </svg>
                                </div>
                            </Tooltip>
                                <Popover
                                    id={id2}
                                    open={openPop2}
                                    anchorEl={anchorEl2}
                                    onClose={handleClose2}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    className='papper'
                                // style={{ padding: '15px', borderRadius: '10px', width: '300px', height: '200px', overflowY: 'scroll' }}
                                >
                                    {![systemMsg, priceAlrt].includes(true) && <>

                                        <div className="display-2 p-10 notify-main-cont cursor" onClick={handleNotificationSeen}>
                                            <div className="display-1 " onClick={(() => { setPriceAlrt(true) })}>
                                                <div className="display-1">
                                                    <SmsIcon sx={{ fontSize: '17px' }} />
                                                </div>

                                                System Notifications
                                                {/* 
                                                <div className='go-svg'>
                                                    <ArrowRightAltIcon />
                                                </div> */}

                                            </div>
                                            <div className="noti-num">
                                                {/* 2 */}
                                                {notifyCount}
                                            </div>
                                        </div>
                                        <div className="display-2 p-10 notify-main-cont cursor" onClick={handlePriceAlertSeen}>
                                            <div className="display-1 " onClick={(() => { setSystemMsg(true) })}>
                                                <div className="display-1">
                                                    <SmsIcon sx={{ fontSize: '17px' }} />
                                                </div>



                                                Price Alert

                                                {/* <div className='go-svg'>
                                                    <ArrowRightAltIcon />
                                                </div> */}

                                            </div>
                                            <div className="noti-num">
                                                {alertCount}
                                            </div>
                                        </div>


                                    </>}

                                    {systemMsg ?
                                        <div className="" style={{ height: '100%' }}>
                                            <ArrowBackIcon sx={{ color: 'var(--clr-font1)', padding: '3px' }} onClick={(() => { getPriceAlerts(); setSystemMsg(false) })} />
                                            {

                                                // notifyData?.length > 0 ?
                                                //     notifyData.map((row, index) => (
                                                //         <div className="display-2 cursor">
                                                //             <div className="display-1">

                                                //                 <div className=" dcard-hd" onClick={() => { seenMessage(row?._id); navigate('/profile', { state: { id: row?.ticket_id, time: row?.seen == false ? row?.time : null, notify: row?._id } }) }}>
                                                //                     {row?.message}
                                                //                 </div>
                                                //             </div>
                                                //             <div className="display-1">
                                                //                 <ChevronRightIcon />
                                                //             </div>
                                                //         </div>
                                                //     )) :
                                                //     <div className=" dcard-hd display-3" style={{ height: '100%' }}>
                                                //         No Notification Found
                                                //     </div>
                                            }

                                            {priceAlerts?.length > 0 ?
                                                priceAlerts.map((row, index) => (
                                                    <div className="display-2 p-10 notify-main-cont cursor">
                                                        <div className="display-1 " onClick={(() => { setSystemMsg(true) })}>
                                                            {/* <div className="display-1">
                                                        <SmsIcon sx={{ fontSize: '17px' }} />
                                                    </div> */}
                                                            <div className="">
                                                                {/* ETH Crosses 2,800 USD */}
                                                                {row?.message}
                                                                <div className="sub-noti">
                                                                    {/* View More */}
                                                                    {row?.createdAt.split('T')[0]}
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <img width="25" height="25" src="https://img.icons8.com/flat-round/25/positive-dynamic.png" alt="positive-dynamic" />
                                                    </div>
                                                )) :
                                                <div className=" dcard-hd display-3" style={{ height: '100%' }}>
                                                    No Alert Found
                                                </div>
                                            }
                                            <div className="display-2 p-10 mt-10 notify-main-cont cursor">
                                                {/* <div className="display-1 " onClick={(() => { setSystemMsg(true) })}> */}
                                                {/* <div className="display-1">
                                                        <SmsIcon sx={{ fontSize: '17px' }} />
                                                    </div> */}
                                                {/* <div className=""> */}
                                                {/* BTC Dips Below 74,000 USD */}

                                                {/* <div className="sub-noti"> */}
                                                {/* View More */}
                                                {/* </div> */}
                                                {/* </div> */}


                                                {/* </div> */}
                                                {/* <img width="25" height="25" src="https://img.icons8.com/office/25/bearish.png" alt="bearish" /> */}
                                            </div>
                                        </div> :
                                        priceAlrt ?
                                            <div className="" style={{ height: '100%' }}>
                                                <ArrowBackIcon sx={{ color: 'var(--clr-font1)', padding: '3px' }} onClick={(() => { getNotification(); setPriceAlrt(false) })} />
                                                {
                                                    // notifyData?.length > 0 ?
                                                    //     notifyData.map((row, index) => (
                                                    //         <div className="display-2 cursor">
                                                    //             <div className="display-1">

                                                    //                 <div className=" dcard-hd" onClick={() => { seenMessage(row?._id); navigate('/profile', { state: { id: row?.ticket_id, time: row?.seen == false ? row?.time : null, notify: row?._id } }) }}>
                                                    //                     {row?.message}
                                                    //                 </div>
                                                    //             </div>
                                                    //             <div className="display-1">
                                                    //                 <ChevronRightIcon />
                                                    //             </div>
                                                    //         </div>
                                                    //     )) :
                                                    //     <div className=" dcard-hd display-3" style={{ height: '100%' }}>
                                                    //         No Notification Found
                                                    //     </div>

                                                    notifyData?.length > 0 ?
                                                        notifyData.map((row, index) => (
                                                            <div className="display-2 p-10 notify-main-cont cursor">
                                                                <div className="display-1 ">
                                                                    <div className="display-1">
                                                                        <SmsIcon sx={{ fontSize: '17px' }} />
                                                                    </div>
                                                                    <div className="">
                                                                        {/* Start 2nd Mission */}
                                                                        {row?.message}
                                                                        <div className="sub-noti">
                                                                            {/* Complete your next mission */}
                                                                            {/* {row?.message} */}
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                                <div className="noti-num x2">

                                                                </div>
                                                            </div>
                                                        )) :
                                                        <div className=" dcard-hd display-3" style={{ height: '100%' }}>
                                                            No Notification Found
                                                        </div>
                                                }
                                            </div> : <></>}
                                </Popover>
                            </Badge>

                            <div className="btn-wallet">
                                {address ? <>{[address?.slice(0, 7), "......", address?.slice(-7)]}</> : <Button varient="contained" className="" onClick={async () => { open() }}>
                                    Connect Wallet
                                </Button>}

                            </div>
                        </Stack>
                    </Box>
                    <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end', width: !matches ? '100%' : 'auto', alignItems: 'center' }}>
                        <div className="dark">
                            <DarkMode />
                        </div>
                        {token === null ? <div className="btn-wallet x2">
                            <Button varient="contained" className="" onClick={() => navigate('/register')}>
                                Sign-up
                            </Button>
                        </div> :
                            <></>}
                        {token === null ? <div className="btn-wallet x2">
                            <Button varient="contained" className="" onClick={() => navigate('/login')}>
                                Log-in
                            </Button>
                        </div> :
                            <></>}
                        {token != null ? <Avatar alt="Remy Sharp"
                            src={user?.image && user?.image !== '' ? user?.image : userData?.image ? userData?.image : userlogo}
                            onClick={handleMenu} /> : <></>}
                    </Stack>
                    <Menu
                        id="menu-appbar"
                        sx={{ mt: '45px' }}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={(() => { navigate(`/profile`); handleClose() })}>Profile</MenuItem>
                        {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                        <MenuItem onClick={(() => { handleLogout() })}>Log-out</MenuItem>

                    </Menu>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    // container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    className='drawer-m'
                    sx={{
                        display: { lg: 'block', xl: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>

            <Toaster />
        </Box >
    );
}



export default DrawerAppBar;
