import { Button, Grid2 } from '@mui/material'
import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Header from '../Header/Header'
import './Allcoin.css'
import stck from '../../Images/stacks.png'
import nem from '../../Images/nem.webp'
import btc from '../../Images/btc.webp'
function Allcoin() {

    const [rows, setRows] = useState([
        {
            name: <div className='display-1'>
                <img width="40" height="40" src={stck} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
                <div className="xm">
                    NEM
                    <div className="min-xm">
                        STX
                    </div>
                </div>
            </div>,
            price: '€0.43463',
            change: '+ 7.96%',
            chart: <svg width="105" height="34" viewBox="0 0 105 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.59961 33H4.4523C4.92595 33 5.37525 32.7901 5.67926 32.4269L11.472 25.5058C11.5309 25.4354 11.5837 25.3601 11.6297 25.2806L15.7431 18.1773C16.2067 17.3766 17.2507 17.1349 18.019 17.6504L26.3036 23.2081C26.94 23.6351 27.7897 23.5508 28.3299 23.0072L31.2154 20.1036C31.7793 19.5362 32.6756 19.4724 33.3141 19.9544L36.313 22.2177C36.7417 22.5412 37.3042 22.6281 37.8105 22.449L43.0371 20.6L48.0071 18.2971C48.1462 18.2327 48.2753 18.1486 48.3904 18.0475L53.5467 13.5181C54.146 12.9916 55.0416 12.987 55.6462 13.5073L59.7773 17.0622C60.0325 17.2818 60.3505 17.4153 60.686 17.4437L66.1465 17.9057H72.8008C73.3037 17.9057 73.7774 17.6691 74.0797 17.2671L79.6132 9.90667C79.6665 9.83568 79.7257 9.76925 79.7901 9.70808L87.9128 1.99234C88.7775 1.17092 90.2046 1.55033 90.5473 2.69274L93.3669 12.0939C93.5345 12.6528 93.9931 13.0761 94.5636 13.1986L98.444 14.0316C99.1383 14.1806 99.6513 14.7695 99.7038 15.4777L100.49 26.0843C100.552 26.9198 101.248 27.566 102.085 27.566H103.6" stroke="#FF6838" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.4523 33H1.59961L101.993 33.3937C102.879 33.3972 103.6 32.6798 103.6 31.7937V29.1215C103.6 28.2625 102.903 27.566 102.044 27.566C101.23 27.566 100.553 26.9377 100.493 26.1255L99.7038 15.4777C99.6513 14.7695 99.1383 14.1806 98.444 14.0316L94.5636 13.1986C93.9931 13.0761 93.5345 12.6528 93.3669 12.0939L90.5472 2.69274C90.2046 1.55033 88.7775 1.17092 87.9128 1.99234L79.7901 9.70808C79.7257 9.76925 79.6665 9.83568 79.6132 9.90667L74.0797 17.2671C73.7774 17.6692 73.3037 17.9057 72.8008 17.9057H66.1465L60.686 17.4437C60.3505 17.4153 60.0325 17.2818 59.7773 17.0622L55.6462 13.5073C55.0416 12.987 54.146 12.9916 53.5467 13.5181L48.3904 18.0475C48.2753 18.1486 48.1462 18.2327 48.0071 18.2971L43.0371 20.6L37.8105 22.449C37.3042 22.6281 36.7417 22.5412 36.313 22.2177L33.3141 19.9544C32.6756 19.4724 31.7793 19.5362 31.2154 20.1036L28.3299 23.0072C27.7897 23.5508 26.94 23.6351 26.3036 23.2081L18.019 17.6504C17.2507 17.1349 16.2067 17.3766 15.7431 18.1773L11.6297 25.2806C11.5837 25.3601 11.5309 25.4354 11.472 25.5058L5.67926 32.4269C5.37525 32.7901 4.92595 33 4.4523 33Z" fill="url(#paint0_linear_17_24476)" />
                <defs>
                    <linearGradient id="paint0_linear_17_24476" x1="53.1996" y1="-60.5623" x2="52.5456" y2="33.3996" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#EF466F" />
                        <stop offset="1" stop-color="#EF466F" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>,
            volume: '6,624,494.71'
        },
        {
            name: <div className='display-1'>
                <img width="40" height="40" src={nem} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
                <div className="xm">
                    NEM
                    <div className="min-xm">
                        STX
                    </div>
                </div>
            </div>,
            price: '€0.43463',
            change: '+ 7.96%',
            chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
                <defs>
                    <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#45B36B" />
                        <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>,
            volume: '6,624,494.71'
        },
        {
            name: <div className='display-1'>
                <img width="40" height="40" src={btc} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
                <div className="xm">
                    BitCoin
                    <div className="min-xm">
                        BTC
                    </div>
                </div>
            </div>,
            price: '€0.43463',
            change: '+ 7.96%',
            chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
                <defs>
                    <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#45B36B" />
                        <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>,
            volume: '6,624,494.71'
        },
        {
            name: <div className='display-1'>
                <img width="40" height="40" src={stck} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
                <div className="xm">
                    NEM
                    <div className="min-xm">
                        STX
                    </div>
                </div>
            </div>,
            price: '€0.43463',
            change: '+ 7.96%',
            chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
                <defs>
                    <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#45B36B" />
                        <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>,
            volume: '6,624,494.71'
        },
        {
            name: <div className='display-1'>
                <img width="40" height="40" src={nem} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
                <div className="xm">
                    NEM
                    <div className="min-xm">
                        STX
                    </div>
                </div>
            </div>,
            price: '€0.43463',
            change: '+ 7.96%',
            chart: <svg width="105" height="34" viewBox="0 0 105 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.59961 33H4.4523C4.92595 33 5.37525 32.7901 5.67926 32.4269L11.472 25.5058C11.5309 25.4354 11.5837 25.3601 11.6297 25.2806L15.7431 18.1773C16.2067 17.3766 17.2507 17.1349 18.019 17.6504L26.3036 23.2081C26.94 23.6351 27.7897 23.5508 28.3299 23.0072L31.2154 20.1036C31.7793 19.5362 32.6756 19.4724 33.3141 19.9544L36.313 22.2177C36.7417 22.5412 37.3042 22.6281 37.8105 22.449L43.0371 20.6L48.0071 18.2971C48.1462 18.2327 48.2753 18.1486 48.3904 18.0475L53.5467 13.5181C54.146 12.9916 55.0416 12.987 55.6462 13.5073L59.7773 17.0622C60.0325 17.2818 60.3505 17.4153 60.686 17.4437L66.1465 17.9057H72.8008C73.3037 17.9057 73.7774 17.6691 74.0797 17.2671L79.6132 9.90667C79.6665 9.83568 79.7257 9.76925 79.7901 9.70808L87.9128 1.99234C88.7775 1.17092 90.2046 1.55033 90.5473 2.69274L93.3669 12.0939C93.5345 12.6528 93.9931 13.0761 94.5636 13.1986L98.444 14.0316C99.1383 14.1806 99.6513 14.7695 99.7038 15.4777L100.49 26.0843C100.552 26.9198 101.248 27.566 102.085 27.566H103.6" stroke="#FF6838" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.4523 33H1.59961L101.993 33.3937C102.879 33.3972 103.6 32.6798 103.6 31.7937V29.1215C103.6 28.2625 102.903 27.566 102.044 27.566C101.23 27.566 100.553 26.9377 100.493 26.1255L99.7038 15.4777C99.6513 14.7695 99.1383 14.1806 98.444 14.0316L94.5636 13.1986C93.9931 13.0761 93.5345 12.6528 93.3669 12.0939L90.5472 2.69274C90.2046 1.55033 88.7775 1.17092 87.9128 1.99234L79.7901 9.70808C79.7257 9.76925 79.6665 9.83568 79.6132 9.90667L74.0797 17.2671C73.7774 17.6692 73.3037 17.9057 72.8008 17.9057H66.1465L60.686 17.4437C60.3505 17.4153 60.0325 17.2818 59.7773 17.0622L55.6462 13.5073C55.0416 12.987 54.146 12.9916 53.5467 13.5181L48.3904 18.0475C48.2753 18.1486 48.1462 18.2327 48.0071 18.2971L43.0371 20.6L37.8105 22.449C37.3042 22.6281 36.7417 22.5412 36.313 22.2177L33.3141 19.9544C32.6756 19.4724 31.7793 19.5362 31.2154 20.1036L28.3299 23.0072C27.7897 23.5508 26.94 23.6351 26.3036 23.2081L18.019 17.6504C17.2507 17.1349 16.2067 17.3766 15.7431 18.1773L11.6297 25.2806C11.5837 25.3601 11.5309 25.4354 11.472 25.5058L5.67926 32.4269C5.37525 32.7901 4.92595 33 4.4523 33Z" fill="url(#paint0_linear_17_24476)" />
                <defs>
                    <linearGradient id="paint0_linear_17_24476" x1="53.1996" y1="-60.5623" x2="52.5456" y2="33.3996" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#EF466F" />
                        <stop offset="1" stop-color="#EF466F" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>,
            volume: '6,624,494.71'
        },
        {
            name: <div className='display-1'>
                <img width="40" height="40" src={btc} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
                <div className="xm">
                    BitCoin
                    <div className="min-xm">
                        BTC
                    </div>
                </div>
            </div>,
            price: '€0.43463',
            change: '+ 7.96%',
            chart: <svg width="105" height="34" viewBox="0 0 105 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.59961 33H4.4523C4.92595 33 5.37525 32.7901 5.67926 32.4269L11.472 25.5058C11.5309 25.4354 11.5837 25.3601 11.6297 25.2806L15.7431 18.1773C16.2067 17.3766 17.2507 17.1349 18.019 17.6504L26.3036 23.2081C26.94 23.6351 27.7897 23.5508 28.3299 23.0072L31.2154 20.1036C31.7793 19.5362 32.6756 19.4724 33.3141 19.9544L36.313 22.2177C36.7417 22.5412 37.3042 22.6281 37.8105 22.449L43.0371 20.6L48.0071 18.2971C48.1462 18.2327 48.2753 18.1486 48.3904 18.0475L53.5467 13.5181C54.146 12.9916 55.0416 12.987 55.6462 13.5073L59.7773 17.0622C60.0325 17.2818 60.3505 17.4153 60.686 17.4437L66.1465 17.9057H72.8008C73.3037 17.9057 73.7774 17.6691 74.0797 17.2671L79.6132 9.90667C79.6665 9.83568 79.7257 9.76925 79.7901 9.70808L87.9128 1.99234C88.7775 1.17092 90.2046 1.55033 90.5473 2.69274L93.3669 12.0939C93.5345 12.6528 93.9931 13.0761 94.5636 13.1986L98.444 14.0316C99.1383 14.1806 99.6513 14.7695 99.7038 15.4777L100.49 26.0843C100.552 26.9198 101.248 27.566 102.085 27.566H103.6" stroke="#FF6838" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.4523 33H1.59961L101.993 33.3937C102.879 33.3972 103.6 32.6798 103.6 31.7937V29.1215C103.6 28.2625 102.903 27.566 102.044 27.566C101.23 27.566 100.553 26.9377 100.493 26.1255L99.7038 15.4777C99.6513 14.7695 99.1383 14.1806 98.444 14.0316L94.5636 13.1986C93.9931 13.0761 93.5345 12.6528 93.3669 12.0939L90.5472 2.69274C90.2046 1.55033 88.7775 1.17092 87.9128 1.99234L79.7901 9.70808C79.7257 9.76925 79.6665 9.83568 79.6132 9.90667L74.0797 17.2671C73.7774 17.6692 73.3037 17.9057 72.8008 17.9057H66.1465L60.686 17.4437C60.3505 17.4153 60.0325 17.2818 59.7773 17.0622L55.6462 13.5073C55.0416 12.987 54.146 12.9916 53.5467 13.5181L48.3904 18.0475C48.2753 18.1486 48.1462 18.2327 48.0071 18.2971L43.0371 20.6L37.8105 22.449C37.3042 22.6281 36.7417 22.5412 36.313 22.2177L33.3141 19.9544C32.6756 19.4724 31.7793 19.5362 31.2154 20.1036L28.3299 23.0072C27.7897 23.5508 26.94 23.6351 26.3036 23.2081L18.019 17.6504C17.2507 17.1349 16.2067 17.3766 15.7431 18.1773L11.6297 25.2806C11.5837 25.3601 11.5309 25.4354 11.472 25.5058L5.67926 32.4269C5.37525 32.7901 4.92595 33 4.4523 33Z" fill="url(#paint0_linear_17_24476)" />
                <defs>
                    <linearGradient id="paint0_linear_17_24476" x1="53.1996" y1="-60.5623" x2="52.5456" y2="33.3996" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#EF466F" />
                        <stop offset="1" stop-color="#EF466F" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>,
            volume: '6,624,494.71'
        },
        {
            name: <div className='display-1'>
                <img width="40" height="40" src={stck} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
                <div className="xm">
                    NEM
                    <div className="min-xm">
                        STX
                    </div>
                </div>
            </div>,
            price: '€0.43463',
            change: '+ 7.96%',
            chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
                <defs>
                    <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#45B36B" />
                        <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>,
            volume: '6,624,494.71'
        },
        {
            name: <div className='display-1'>
                <img width="40" height="40" src={nem} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
                <div className="xm">
                    NEM
                    <div className="min-xm">
                        STX
                    </div>
                </div>
            </div>,
            price: '€0.43463',
            change: '+ 7.96%',
            chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
                <defs>
                    <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#45B36B" />
                        <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>,
            volume: '6,624,494.71'
        },
        {
            name: <div className='display-1'>
                <img width="40" height="40" src={btc} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
                <div className="xm">
                    BitCoin
                    <div className="min-xm">
                        BTC
                    </div>
                </div>
            </div>,
            price: '€0.43463',
            change: '+ 7.96%',
            chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
                <defs>
                    <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#45B36B" />
                        <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>,
            volume: '6,624,494.71'
        },
    ])
    return (
        <div>
            <Header />
            <Grid2 container justifyContent={'center'} sx={{ marginTop: '100px' }}>

                <Grid2 item size={{ xs: 12, sm: 12, md: 12, lg: 11, xl: 10 }}>
                    <div className="market-table">
                        <TableContainer className='marktab'>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Asset</TableCell>
                                        <TableCell align="center">Price</TableCell>
                                        <TableCell align="center">Change (24h)</TableCell>
                                        <TableCell align="center">Chart</TableCell>
                                        <TableCell align="center">Volume</TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, i) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="center">{row.price}</TableCell>
                                            <TableCell align="center" sx={{ color: i % 2 === 0 ? '#03A692 !important' : '#E62C50 !important' }}>{row.change}</TableCell>
                                            <TableCell align="center" >{row.chart}</TableCell>
                                            <TableCell align="center">{row.volume}</TableCell>
                                            <TableCell align="center">
                                                <Button>
                                                    Buy
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid2>

            </Grid2>
        </div >
    )
}

export default Allcoin
