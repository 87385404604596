
import './App.css';
import './Styles.css';
import './Media.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header/Header'
import Profile from './Components/Profile/Profile';
import Landing from './Components/Landing/Landing'
import Login from './Components/Authendication/Login/Login';
import Register from './Components/Authendication/Register/Register';
import TwoFactor from './Components/Authendication/TwoFactor';
import ForgetPaswd from './Components/Authendication/ForgetPaswd';
import ResetPaswd from './Components/Authendication/ResetPaswd';
// import Market from './Components/Market/Market';
import Allcoin from './Components/Allcoin/Allcoin';
import Verify from './Components/Authendication/Verify';
import Subscription from './Components/Subscription/Subscription';
import Market from './Components/Market/Market';
import Policy from './Components/Policy';
import Resend from './Components/Authendication/Resend';
import PrivateRoute from './PrivateRoute/PrivateRoute';

import Support from './Components/Support/Support';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/header' element={<Header />} />
          <Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path='/' element={<Landing />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/2fa' element={<TwoFactor />} />
          <Route path='/forgot' element={<ForgetPaswd />} />
          <Route path='/reset' element={<ResetPaswd />} />
          <Route path='/verify' element={<Verify />} />
          <Route path='/resend' element={<Resend />} />
          <Route path='/allcoin' element={<Allcoin />} />
          <Route path='/subscription' element={<Subscription />} />
          <Route path='/market' element={<PrivateRoute><Market /></PrivateRoute>} />
          <Route path='/policy' element={<Policy />} />
          <Route path='/terms' element={<Policy />} />
          <Route path='/checklist' element={<Policy />} />
          <Route path='/support' element={<Support />} />
        </Routes>

      </Router>
    </div>
  );
}

export default App;
