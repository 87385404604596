import { Grid2 } from '@mui/material'
import React from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'

function Policy() {
    return (
        <div className='auth-page' style={{ height: '100vh', overflow: 'hidden' }}>
            <Header />
            <Grid2 container>

                <Grid2 item size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                    <div className="cmgsoon">
                        Coming Soon!!
                    </div>
                </Grid2>

            </Grid2>
            <div className="" >
                <Footer style={{ position: 'sticky', bottom: 0 }} />
            </div>

        </div>
    )
}

export default Policy
