import React, { useState, useContext, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import "./Landing.css";
import { Button } from "@mui/material";
import Support1 from "../../Images/support-1.svg";
import Support2 from "../../Images/support-2.svg";
import Support3 from "../../Images/support-3.svg";
import Support4 from "../../Images/support-4.svg";
import Support5 from "../../Images/support-5.svg";
import Banner from "../../Images/banner-img.webp";
import Bannerlight from "../../Images/bannerlight.webp";
import Graph from "../../Images/graph-chart.webp";
import Graphlight from "../../Images/graphlght.webp";
import Btc from "../../Images/btc.svg";
import Google from "../../Images/google-play.svg";
import Eth from "../../Images/eth.svg";
import ada from "../../Images/ada.svg";
import Chart from "../../Images/green-chart.png";
import Chart2 from "../../Images/red-chart.png";
import Trade from "../../Images/trading-view.webp";
import Cta from "../../Images/cta-img.webp";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Device from "../../Images/device-img.webp";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import AppleIcon from "@mui/icons-material/Apple";
import Crystal from "../../Images/crystal.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import BTC from "../../Images/coin-1.svg";
import USDT from "../../Images/coin-2.svg";
import Coin from "../../Images/coin-3.svg";
import { Link } from 'react-router-dom';
import Logo from "../../Images/click-logo.png";
import Logolight from "../../Images/lighrlogo2.webp";
import TradeChart from "../../Images/trade-chart.webp";
import TradeChartlight from "../../Images/tclight.webp";
// import Discover from "../../Images/discover-lap.webp";
import Discover from "../../Images/dis-lap.png";
import Discoverlight from "../../Images/discoverlight.webp";
import { useNavigate } from 'react-router-dom';
import DiscoverChart from "../../Images/discover-chart.webp";
import DiscoverChartlight from "../../Images/dclight.webp";

import WhatshotIcon from "@mui/icons-material/Whatshot";

import { ThemeContext } from '../DarkMode/ThemeProvider';
import Axios from "../Axios";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

// const theme = localStorage.getItem('theme')



const Landing = () => {

  const { isLightMode, handleDarkmode } = useContext(ThemeContext);
  const [render, setRender] = useState('')
  const [plan, setPlan] = useState([])
  const [subscribedPlan, setSubscribedPlan] = useState([])
  const [msg, setMsg] = useState()
  const navigate = useNavigate();

  const handleRender = (data) => {
    setRender(data)
  }

  const token = localStorage.getItem('Rikosta')

  const handleNavigate = async (page) => {

    const tok = await localStorage.getItem('Rikosta');

    if (tok) {
      await navigate(page);
    } else {
      await navigate('/register');
    }

  }

  const getPlan = async () => {
    try {
      const { data } = await Axios.get('/admin/getPlan')
      setPlan(data?.result)
    } catch (error) {
      console.log("🚀 ~ getPlan ~ error:", error)
    }
  }

  const getSubscibedPlan = async () => {
    try {
      const { data } = await Axios.get('/users/subscribedPlan', {
        headers: {
          Authorization: window.localStorage.getItem('Rikosta')
        }
      })
      if (data?.success == true) {
        if (data?.message === "Plan Subscribed") {
          setMsg(data.message)
          setSubscribedPlan(data?.result?.plans)
        }
        // setStatus(true)
        // setPlanId(data?.result)
      }
    } catch (error) {
      console.log("🚀 ~ getSubscibedPlan ~ error:", error)
    }
  }


  useEffect(() => {
    if (token) {

      getSubscibedPlan()
    }
    getPlan()

  }, [])

  return (
    <div className="landing-full">
      <Header renderComp={handleRender} />
      <section className="banner-part">
        <div className="contain-width">
          <Box>
            <Grid item container spacing={0}>
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                <h1>Easy Profitable Crypto Trading</h1>
                <p>
                  A revolutionary platform to make profitable crypto trading
                  accessible to everyone, supported by the $CANT token. The
                  platform that bridges the gap between complex crypto trading
                  and universal access.
                </p>
                {
                  token == null ?
                    <div className="free-actb-btn">
                      <Button onClick={() => handleNavigate('/market')} >Get Free Account</Button>
                    </div> :
                    <></>
                }

                <div className="supported-part display-1">
                  <p>Supported :</p>
                  <div className="supported-logos display-1">
                    <div className="supported-img">
                      <img
                        src={Support1}
                        alt="Binance-logo"
                        width={47}
                        height={47}
                      />
                    </div>
                    <div className="supported-img">
                      <img
                        src={Support2}
                        alt="Bybit-logo"
                        width={47}
                        height={47}
                      />
                    </div>
                    <div className="supported-img">
                      <img
                        src={Support3}
                        alt="Bybit-logo"
                        width={47}
                        height={47}
                      />
                    </div>
                    <div className="supported-img">
                      <img
                        src={Support4}
                        alt="Bybit-logo"
                        width={47}
                        height={47}
                      />
                    </div>
                    <div className="supported-img">
                      <img
                        src={Support5}
                        alt="Bybit-logo"
                        width={47}
                        height={47}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 5 }}>
                <div className="banner-img">
                  {isLightMode ? <img src={Bannerlight} alt="Banner-img" fetchPriority="high" /> : <img src={Banner} alt="Banner-img" fetchPriority="high" />}
                  {/* <img src={theme ? Bannerlight : Banner} alt="Banner-img" fetchPriority="high" /> */}
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 3 }}>
                <div className="report-detail">
                  <h2>+ 96%</h2>
                  <p>profitable result using Our signals</p>
                </div>
                <div className="report-detail">
                  <h2>$76 Bilion</h2>
                  <p>24h trading volume duxica exchange</p>
                </div>
                <div className="report-detail">
                  <h2>600+</h2>
                  <p>Cryptocurrencies listed</p>
                </div>
                <div className="report-detail">
                  <h2>80 milion</h2>
                  <p>Registered users who trust Clicks & Trade</p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>

      <section className="pro-use">
        <div className="contain-width">
          <Box>
            <Grid item container spacing={0} className="row-flex">
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 7, xl: 7 }}>
                <div className="trade-pro-txt">
                  <h2>
                    You Can Trade Like a Pro to use CLICK AND TRADE PROFITABLE !
                  </h2>
                  <div className="trade-now">
                    <Button onClick={() => handleNavigate("/market")} class="btn-primary">Trade Now</Button>
                  </div>
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 5 }}>
                <div className="graph-chart">
                  {isLightMode ? <img src={Graphlight} alt="gLight" /> : <img src={Graph} alt="gDark" />}

                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>

      <section className="how-we-help">
        <div className="contain-width">
          <Box>
            <Grid item container spacing={3}>
              <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                <div className="trade-details display-1">
                  <div className="trade-box">
                    <div className="trade-coin display-1">
                      <div className="coin-img">
                        <img src={BTC} alt="btc" />
                      </div>
                      <div className="coin-img">
                        <p className="display-1">
                          BTC/USDT <span className="red-bg">-0.79%</span>
                        </p>
                        <p>36,641.20</p>
                        <p>36,641.20</p>
                      </div>
                    </div>
                    <div className="trade-coin display-1">
                      <div className="coin-img">
                        <img src={USDT} alt="btc" />
                      </div>
                      <div className="coin-img">
                        <p className="display-1">
                          BTC/USDT <span className="green-bg">-0.79%</span>
                        </p>
                        <p>36,641.20</p>
                        <p>36,641.20</p>
                      </div>
                    </div>
                    <div className="trade-coin display-1">
                      <div className="coin-img">
                        <img src={Coin} alt="btc" />
                      </div>
                      <div className="coin-img">
                        <p className="display-1">
                          BTC/USDT <span className="green-bg">-0.79%</span>
                        </p>
                        <p>36,641.20</p>
                        <p>36,641.20</p>
                      </div>
                    </div>
                  </div>

                  <div className="trade-direct">
                    <Link to="/market">Trade</Link>
                  </div>
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 5, xl: 5 }}>
                <div className="clicks-logo-cnt">
                  <div className="curve-line">
                    <img
                      src={isLightMode ? Logolight : Logo}
                      alt="clicks-logo"
                      width={250}
                      height={60}
                      fetchPriority="low"
                    />
                  </div>
                  <div className="help-pro-cnt">
                    <h2>How We Help You Trade Like a Pro Without Being One</h2>
                    <p>
                      Are you tired of the constant losing, confusing advice,
                      and all the FUD and FOMO from the crypto industry?
                    </p>
                    <p>So are we.</p>
                    <p>
                      Truth is, even if you’re a decent trader, crypto is
                      absolutely brutal. You need years of experience, advanced
                      tools, and complex trading strategies to stand any chance
                      of winning in the long run.
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 3, xl: 3 }}>
                <div className="trade-chart">
                  {isLightMode ? <img src={TradeChartlight} alt="trade-chart" fetchPriority="low" width={192} height={186} /> : <img src={TradeChart} alt="trade-chart" fetchPriority="low" width={192} height={186} />}
                  {/* <img src={TradeChart} alt="trade-chart" fetchPriority="low" width={192} height={186} /> */}
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>

      <section className="how-work">
        <div className="contain-width">
          <h2>Here’s how it works:</h2>
          <div className="trading-signal">
            <Box>
              <Grid item container spacing={2}>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <h3>Choose a trading signal</h3>
                  <p>
                    Our algorithm scans the market for high- probability trade
                    signals 24/7. Our experts handpick the best ones and give
                    them to you - entry price, targets, and stop loss included.
                    No research or planning needed.
                  </p>
                </Grid>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <div className="bitcoin-tag display-2">
                    <div className="btc-name display-1">
                      <div className="btc-img-name display-1">
                        <div className="btc-img">
                          <img src={Btc} alt="btc" />
                        </div>
                        <div className="coin-name">
                          <p>Bitcoin</p>
                          <p>BTC</p>
                        </div>
                      </div>

                      <div className="chart">
                        <img src={Chart} />
                      </div>
                      <div className="coin-value">
                        <p>$2,509.75</p>
                        <p className="green-text">+9.77%</p>
                      </div>
                    </div>
                  </div>
                  <div className="bitcoin-tag display-2">
                    <div className="btc-name display-1">
                      <div className="btc-img-name display-1">
                        <div className="btc-img">
                          <img src={Eth} alt="btc" />
                        </div>
                        <div className="coin-name">
                          <p>Ethereum</p>
                          <p>ETH</p>
                        </div>
                      </div>

                      <div className="chart">
                        <img src={Chart2} />
                      </div>
                      <div className="coin-value">
                        <p>$2,509.75</p>
                        <p className="green-text">+9.77%</p>
                      </div>
                    </div>
                  </div>
                  <div className="bitcoin-tag display-2">
                    <div className="btc-name display-1">
                      <div className="btc-img-name display-1">
                        <div className="btc-img">
                          <img src={ada} alt="btc" />
                        </div>
                        <div className="coin-name">
                          <p>Cardano</p>
                          <p>ADA</p>
                        </div>
                      </div>

                      <div className="chart">
                        <img src={Chart} />
                      </div>
                      <div className="coin-value">
                        <p>$2,509.75</p>
                        <p className="green-text">+9.77%</p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className="trading-signal">
            <Box>
              <Grid item container spacing={2}>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <h3>Just copy our plan</h3>
                  <p>
                    All you do is copy a signal and customize your trade plan to
                    suit your strategy and required Risk:Reward
                  </p>
                </Grid>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <div className="trade-coin display-1">
                    <div className="coin-img">
                      <img src={BTC} alt="btc" />
                    </div>
                    <div className="coin-img">
                      <p className="display-1">
                        BTC/USDT <span className="red-bg">-0.79%</span>
                      </p>
                      <p>36,641.20</p>
                      <p>36,641.20</p>
                    </div>
                  </div>
                  <div className="text-center copy-text">
                    <Button className="btn-primary">Copy</Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>

          <div className="trading-signal">
            <Box>
              <Grid item container spacing={2}>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <h3>Execute your Trade</h3>
                  <p>
                    Go directly to your exchange, use our advanced trading
                    terminal or our binance.com connection to complete your
                    trade plan.
                  </p>
                </Grid>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <div className="bitcoin-trade text-center">
                    <img src={Trade} alt="trade" width={329} height={352} />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </section>

      <section className="discover-endless">
        <div className="contain-width">
          <h2 style={{ color: isLightMode && '#000' }}>Discover endless possibilities in the world of Trading.</h2>
          <div className="trading-possiblity text-center">
            <img src={isLightMode ? Discoverlight : Discover} alt="discover" fetchPriority="low" />
          </div>
          <div className="trade-view text-center">
            <img src={isLightMode ? DiscoverChartlight : DiscoverChart} alt="discover-chat" fetchPriority="low" />
          </div>

          <div className="start-trade text-center display-3">

            <Button> <WhatshotIcon />Start Trading 96% Profitable</Button>
          </div>
        </div>
      </section>

      <section className="cta-box" style={{ background: isLightMode && 'none' }}>
        <div className="contain-width">
          <Grid item container spacing={2}>
            <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
              <h2>Making your Crypto Trades Easier and more Profitable!</h2>
              <p>
                Clicks and Trades is really built by traders for traders and
                offers crypto trading signals to maximize your profit(s). It is
                backed by <span>$CANT</span>.
              </p>
              <div className="buy-token">
                <Button onClick={() => handleNavigate("/market")} class="btn-primary">Buy Token</Button>
              </div>
            </Grid>
            <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
              <div className="cta-img">
                <img src={Cta} alt="Cta" fetchPriority="low" width={392} height={431} />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="upgarde-plan">
        <div className="contain-width">
          <h2>Upgrade & Start Trading Like a Pro!</h2>
          {
            msg == 'Plan Subscribed' ?

              <Grid item container spacing={2} className="grid-row" sx={{ justifyContent: subscribedPlan && 'center' }}>
                {subscribedPlan && subscribedPlan?.map((row, i) => (
                  <Grid item size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                    <div className="trade-pro">
                      <h5 className="display-3">{row?.Title}</h5>
                      <h3 className="display-3">{row?.Currency}{row?.Amount}</h3>
                      <div className="text-center">per {row?.Duration} {row?.type}</div>


                      <div className="pro-ticket display-3">
                        <Button>Subscribed</Button>
                      </div>
                      <List >
                        <ListItem>Free Sign-Up</ListItem>
                        <ListItem>Buy and Sell Crypto
                        </ListItem>
                        <ListItem>
                          End-to-End Encryption
                        </ListItem>
                        <ListItem>
                          2 - Factor Authentication
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                ))}
              </Grid>
              :

              <Grid item container spacing={2} className="grid-row">
                {plan && plan?.map((row, i) => (
                  <Grid item size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                    <div className="trade-pro">
                      <h5 className="display-3">{row?.Title}</h5>
                      <h3 className="display-3">{row?.Currency}{row?.Amount}</h3>
                      <div className="text-center">per {row?.Duration} {row?.type}</div>

                      <div className="pro-ticket display-3">
                        <Button>Buy</Button>
                      </div>
                      <List >
                        <ListItem>Free Sign-Up</ListItem>
                        <ListItem>Buy and Sell Crypto
                        </ListItem>
                        <ListItem>
                          {/* Safekeeping Your Digital Assets */}
                          End-to-End Encryption
                        </ListItem>
                        <ListItem>
                          2 - Factor Authentication
                        </ListItem>

                      </List>
                    </div>
                  </Grid>
                ))
                }
                {/* <Grid item size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
              <div className="trade-pro ultra-pro">
                <h5 className="display-3">Standard</h5>
                <h3 className="display-3">€250</h3>

                <div className="pro-ticket display-3">
                  <Button>BUY</Button>
                </div>
                <List
                >
                  <ListItem>Free Sign-Up</ListItem>
                  <ListItem>Buy and Sell Crypto
                  </ListItem>
                  <ListItem>End-to-End Encryption
                  </ListItem>

                  <ListItem>
                    2 - Factor Authentication
                  </ListItem>
                  <ListItem>

                  </ListItem>

                </List>
              </div>
            </Grid> */}
              </Grid>
          }
        </div>
      </section>

      <section className="hand-device">
        <div className="contain-width">

          <div className="app-download">
            {/* <div className="crystal-card">
            <img src={Crystal} alt="crystal"/>
          </div> */}
            <Grid item container spacing={2}>
              <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <div className="device-img">
                  <img src={Device} alt="hand-device" fetchPriority="low" />
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <div className="hand-crystal"><h2>Explore at hand devices</h2></div>

                <p>
                  The Future of cryptocurrency exchange is here with Clicks & Trades. Instant access, simplified. Trading smoothly, amplified.
                </p>

                <div className="range">
                  <p className="trade-fast">Clicks & trade up to 100x faster</p>
                  <div className="progress-bar">
                    <div className="app-progress other-app">
                      <div className="app-bar display-2">
                        <p>Other apps</p>
                        <p>2X</p>
                      </div>
                      <BorderLinearProgress variant="determinate" value={40} />
                    </div>
                    <div className="app-progress">
                      <div className="app-bar display-2">
                        <p>EasyA</p>
                        <p>100x</p>
                      </div>
                      <BorderLinearProgress variant="determinate" value={100} />
                    </div>
                  </div>
                </div>

                <div className="app-store display-1">
                  <Link to="" className="cursor">
                    <div className="download-store display-1">
                      <AppleIcon />
                      <p>
                        Download on the <span>App Store</span>
                      </p>
                    </div>
                  </Link>
                  <Link to="" className="cursor">
                    <div className="download-store display-1">
                      <img src={Google} />
                      <p>
                        GET IT ON <span>Google Play</span>
                      </p>
                    </div>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>

      <section className="faq">
        <div className="contain-width">
          <h2>FAQ</h2>
          <div className="question-part">
            <Accordion className="lan-accord">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>What are ClicksandTrades?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  ClicksandTrades is represented by a team of expert top traders who have experienced over the years that trading and investing is de facto 'easy' if you know exactly how, when, what, etc. That's why we've built a simple, understandable crypto trading signals platform. Our mission is to provide everyone with profitable crypto trading, regardless of everyone's experience.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="lan-accord">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Is it safe to invest in the $CANT token and the ClicksandTrades platform?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  As mentioned above, the platform is of course built on a solid technical base, which includes leading security features. Whether you invest in $CANT or trade with ClicksandTrades, your money is safe. And because the protection and security of customer funds is one of our top priorities, we have opted for management of treasury assets by several key holders. Two or more private key signatures are required to execute a successful transaction and ensure the security and integrity of the treasury function.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="lan-accord">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>
                  Can I start trading with just $1?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  offers a variety of trading and investment options that allow you to buy and sell crypto with as little as $1. With products like zero-fee conversion, spot trading, margin trading, and futures trading, you can try out different strategies and build your skills on Dreamster without worrying about entry costs.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="lan-accord">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>
                  Is there an exchange limit between fiat and crypto?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  With Dreamster, you can exchange between fiat and cryptocurrencies without restrictions, making trading your assets easier than ever. With support for over 50 fiat currencies through P2P markets and credit/debit card channels, Dreamster offers a seamless exchange experience for all users.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Landing;
