const consts = {
    MainUrl: "https://alpha.clicksandtrades.com",
    BackendUrl: "https://alpha.clicksandtrades.com/clickapi",

    chainId: "0x1",
    contractAddress: "0x51313a73bee2b4ba7fdc01333b8329cb9bf41760",
    ownerAddress: "0x1D69ECbd06B17D4a11832a81971B2ef95B4526e6",

    // chainId: "0xaa36a7",
    // MainUrl: "http://localhost:3000/",
    // BackendUrl: "http://localhost:3500/clickapi",
    // socketUrl: "http://localhost:3500"
    socketUrl: "https://alpha.clicksandtrades.com/"
}

export default consts;