import { Box, Button, Grid22 } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react'
// import Header from '../Header/Header'
import './Suport.css'
import SendIcon from '@mui/icons-material/Send';
import Grid2 from '@mui/material/Grid2'
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Axios from '../Axios';
import moment from 'moment';
import io from "socket.io-client";
import consts from '../../constant';
import { toast, ToastContainer } from 'react-toastify';
import Modal from '@mui/material/Modal';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { TextField } from '@mui/material'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Badge from '@mui/material/Badge';
import { useNavigate, useLocation } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'var(--clr-bg)',
    // border: '2px solid #000',
    // boxShadow: 24,
    borderRadius: '12px',
    p: 4,
};

const drawerWidth = 240;
function Support({ state, time }) {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    const [chat, setChat] = useState([
        { img: 'https://imgs.search.brave.com/IB4ponUTbzWETNl95cvLsyB6zsOzvyfNbFkElz0akrE/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90My5m/dGNkbi5uZXQvanBn/LzA1LzcxLzE1LzYy/LzM2MF9GXzU3MTE1/NjI1OF9RWWFYVUM4/a2pCTmIxaHU5d3h3/dnRDV1VqRnhiWXE5/NC5qcGc', name: 'Sankar', noftify: 'Hi da How are you doing long time no see' },
        { img: 'https://imgs.search.brave.com/IB4ponUTbzWETNl95cvLsyB6zsOzvyfNbFkElz0akrE/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90My5m/dGNkbi5uZXQvanBn/LzA1LzcxLzE1LzYy/LzM2MF9GXzU3MTE1/NjI1OF9RWWFYVUM4/a2pCTmIxaHU5d3h3/dnRDV1VqRnhiWXE5/NC5qcGc', name: 'Barath', noftify: 'Sankar Eod Podra' },
        { img: 'https://imgs.search.brave.com/IB4ponUTbzWETNl95cvLsyB6zsOzvyfNbFkElz0akrE/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90My5m/dGNkbi5uZXQvanBn/LzA1LzcxLzE1LzYy/LzM2MF9GXzU3MTE1/NjI1OF9RWWFYVUM4/a2pCTmIxaHU5d3h3/dnRDV1VqRnhiWXE5/NC5qcGc', name: 'Priya', noftify: 'Saami Ac ah konjam kammi pannuda' },
        { img: 'https://imgs.search.brave.com/IB4ponUTbzWETNl95cvLsyB6zsOzvyfNbFkElz0akrE/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90My5m/dGNkbi5uZXQvanBn/LzA1LzcxLzE1LzYy/LzM2MF9GXzU3MTE1/NjI1OF9RWWFYVUM4/a2pCTmIxaHU5d3h3/dnRDV1VqRnhiWXE5/NC5qcGc', name: 'Gowtham', noftify: 'da figma unnaku send panniten' },


    ])

    //personal chat
    const navigate = useNavigate()
    const location = useLocation()
    const [perChat, setPerChat] = useState([chat[0].name])
    const [mdCHeck, setMdCHeck] = useState(false)
    const [open, setOpen] = useState(false);
    const handleOpen = (e) => {
        setOpen(true)
    }
    const handleClose = (e) => { setOpen(false) }

    const [playCard, setPlaycard] = useState([])
    const [value, setValue] = useState('active')
    const [selcMsg, setSelcMsg] = useState()
    const [room_id, setRoom_id] = useState()
    const [ticketData, setTicketData] = useState({})
    const [showd, setShowd] = useState()
    const [messages, setMessages] = useState([]);
    const [emoji, setEmoji] = useState('');
    const isMounted = useRef(false);
    const isReceived = useRef(false)
    const [title, setTitle] = useState()
    const [titleErr, setTitleErr] = useState(null)
    const [msg, setMsg] = useState()
    const [msgErr, setMsgErr] = useState(null)
    const [load, setLoad] = useState(false)

    // console.log(title, titleErr, msg, msgErr, 'logss');

    const socketInstance = io.connect(consts.socketUrl, { path: "/socket" });
    // const socketInstance = io.connect(consts.socketUrl);


    // console.log(selcMsg, 'selcMsg');


    const handleChangeRadio = async (e) => {

        setValue(e.target.value)
    }
    // console.log(value, 'setValue');

    // console.log(perChat, 'perChat');

    const [display, setDisplay] = useState()


    // console.log(perChat, 'perchat');

    //search

    const [search, setSearch] = useState('')

    const [message, setMessage] = useState([]);
    const [inputValue, setInputValue] = useState('');


    const [updated, setUpdated] = useState('');



    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSend();
        }
    };


    const handleClickSend = (e) => {
        // 👇 "message" stores input field value

        if (updated?.trim() !== '') {
            setMessage([...message, updated])
            setUpdated('')
        }
    };

    const handleDeleteTodo = (index) => {
        const newTodos = [...message];
        newTodos.splice(index, 1);
        setMessage(newTodos);
    };

    //image upload

    const [imageUrl, setImageUrl] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        // console.log(reader, "result of file");

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (emoji.trim() !== '') {
            setMessages([...messages, emoji.trim()]);
            setEmoji('');
        }
        updateTicket()
    };

    const handleChange = (event) => {
        setEmoji(event.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const updateTicket = async () => {
        try {
            if (ticketData.Status == 'closed') {
                toast.error("Your Ticket is Closed")
            }
            else {
                socketInstance.emit('join', {
                    room: room_id
                })
                socketInstance.emit('send', {
                    room: room_id,
                    message: emoji,
                    seen: false,
                    id: 'user',
                })
                const payload = {
                    id: room_id,
                    message: emoji
                }
                const { data } = await Axios.post('/support/updateticket', payload,
                    {
                        headers: {
                            Authorization: window.localStorage.getItem('Rikosta')
                        }
                    }
                )
                if (data.success) {
                    // const newMessage = {
                    //     msg: emoji,
                    //     seen: false,
                    //     id: "admin",
                    //     time: Date.now()
                    // };

                    // setSelcMsg(prevSelcMsg => ({
                    //     ...prevSelcMsg,
                    //     Message: [...prevSelcMsg.Message, newMessage]
                    // }));
                }
            }
        } catch (error) {
            console.log('🚀 ~ updateTickets ~ error', error);
        }
    }


    const getTickets = async () => {
        try {
            const { data } = await Axios.post('/support/getUserTicket', { type: value }, {
                headers: {
                    Authorization: window.localStorage.getItem('Rikosta'),
                }
            })
            if (data?.success == true) {
                setPlaycard(data?.result)
            }
            else {
                setPlaycard([])
            }
        } catch (error) {
            console.log("🚀 ~ getTickets ~ error:", error)
        }
    }

    const getOneTicket = async (id) => {
        try {
            const { data } = await Axios.post('/support/getOneTicket', { id: id, type: 'Count' },
                {
                    headers: {
                        Authorization: window.localStorage.getItem('Rikosta')
                    }
                }
            )
            if (data.success) {
                setSelcMsg(data?.result)
                setRoom_id(data?.result?._id)
                setTicketData(data?.result)
            }
        } catch (error) {
            console.log("🚀 ~ getOneTicket ~ error:", error)
        }
    }

    const msgSeen = async (id) => {

        try {
            await Axios.post('/support/messageseen', { id: id }, {
                headers: {
                    Authorization: window.localStorage.getItem('Rikosta'),
                }
            })
        } catch (error) {
            console.log("🚀 ~ msgSeen ~ error:", error)
        }
    }

    const updateAgeById = (id) => {
        // Create a copy of the current array
        const updatedArray = playCard?.map(item => {
            // Check if the current item's id matches the id we want to update
            if (item._id == id) {
                // Return a new object with updated age
                return { ...item, msgcount: 0 };
            }
            // Return the item unchanged if it doesn't match
            return item;
        });

        // Update state with the new array
        setPlaycard(updatedArray);
    };


    useEffect(() => {
        getTickets()
    }, [value])

    useEffect(() => {

        socketInstance.on('connect', () => {
            console.log('Connected to server');
        });

        // socketInstance.on('message', (data) => {
        //   console.log(`Received message: ${data}`);
        // });

        socketInstance.on('connect_error', (error) => {
            console.log('Connection error:', error);
        });

        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        }
    }, []);

    useEffect(() => {
        if (room_id != undefined && !isReceived.current) {
            socketInstance.emit('join', { room: room_id });

            const handleReceiveReason = (data) => {
                // console.log(data, 'receiveReason');
                setTicketData({ ...ticketData, reason: data.reason, Status: data.status });
            };

            socketInstance.on("receiveReason", handleReceiveReason);
            isReceived.current = true;

            return () => {
                console.log('Cleanup: off receiveReason');
                socketInstance.off("receiveReason", handleReceiveReason);
                isReceived.current = false;
            };
        }
    }, [room_id]);

    useEffect(() => {
        if (room_id != undefined && !isMounted.current) {
            // Join the room
            socketInstance.emit('join', { room: room_id });

            // Event listener for receiving messages
            const handleReceive = async (data) => {
                // console.log(data, 'ismounted time');
                const newMessage = {
                    msg: data.message,
                    seen: data.seen,
                    id: data.id,
                    time: Date.now(),
                };
                setSelcMsg(prevSelcMsg => ({
                    ...prevSelcMsg,
                    Message: [...prevSelcMsg.Message, newMessage],
                }));
                setTimeout(async () => {
                    await msgSeen(room_id);
                }, [2000])
            };

            socketInstance.on("receive", handleReceive);

            isMounted.current = true;

            // Cleanup listener when component unmounts or room_id changes
            return () => {
                socketInstance.off("receive", handleReceive);
                isMounted.current = false;
            };
        }
    }, [room_id]);

    // console.log(state, time, 'state');

    useEffect(() => {
        if (state) {
            setShowd(state);
            getOneTicket(state);
            msgSeen(state);
            updateAgeById(state);
            setTimeout(() => {
                navigate(location.pathname, { replace: true, state: null });
                getTickets()
            }, 5000)
        }
    }, [state])


    const createTicket = async () => {
        if (title == undefined || title == '') {
            setTitleErr('Please Enter Title')
        }
        else if (msg == undefined || msg == '') {
            setMsgErr('Please Enter Message')
        }
        else {
            setLoad(true)
            const payload = {
                Title: title,
                message: msg
            }
            try {
                const { data } = await Axios.post('/support/createTicket', payload, {
                    headers: {
                        Authorization: window.localStorage.getItem('Rikosta')
                    }
                })
                if (data.success) {
                    toast.success(data.message)
                    handleClose()
                    setLoad(false)
                    getTickets()
                    setTitle()
                    setMsg()
                }
                else {
                    toast.error(data.message)
                }
            } catch (error) {
                console.log("🚀 ~ createTicket ~ error:", error)
                toast.error(error.response.data.message)
                setLoad(false)
            }
        }
    }

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        // console.log(`${date.getHours()}:${date.getMinutes()}`, 'formatTIme');

        return `${date.getHours()}:${date.getMinutes()}`;
    };

    // internal css


    const msgBg = {
        height: '670px',
        overflowY: 'scroll',
        margin: '0px',
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        opacity: '0.7'
    }



    const chatContact = (
        <div className='msglist'>
            <div className='msg-list-head display-2'>
                <h2 className='chats'>
                    Chats
                </h2>

                <div id="firstFilter" class="filter-switch">
                    <input checked="" id="option1" name="options" type="radio" value={'active'} onChange={(e) => { handleChangeRadio(e) }} />
                    <label class="option" for="option1">Opened</label>
                    <input id="option2" name="options" type="radio" value={'closed'} onChange={(e) => { handleChangeRadio(e) }} />
                    <label class="option" for="option2">Closed</label>
                    <span class="background"></span>
                </div>

            </div>
            <div className="display-4 chats cursor p-10" style={{ width: 'fit-content', marginLeft: 'auto' }} onClick={handleOpen}>
                <div className="display-1">
                    <ControlPointIcon />
                </div>
                Create
            </div>
            <div className='msg-list-input'>

                <div class="group" onClick={() => { setSearch('') }}>
                    <svg viewBox="0 0 24 24" aria-hidden="true" class="search-icon">
                        <g>
                            <path
                                d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                            ></path>
                        </g>
                    </svg>

                    <input
                        id="query"
                        class="input"
                        type="search"
                        placeholder="Search..."
                        name="searchbar"
                        onChange={((e) => { setSearch(e.target.value) })}
                    />
                </div>


            </div>
            <div className='msg-list-area '>
                {playCard.length > 0 ?
                    playCard.filter((obj) => {
                        return (
                            search === undefined || search == '' ? obj : obj?.Title?.toLowerCase().includes(search)
                        )

                    }).map((row, ind) => {
                        return (
                            // <div key={ind} className={perChat?.name !== row?.name ? 'msg-list-chat-main display-1 cursor' : 'msg-list-chat-main display-1 cursor active'} onClick={(() => { setPerChat(row, ind); setMdCHeck(true) })}>

                            <div key={ind} className={row?._id !== showd ? 'msg-list-chat-main display-2 cursor' : 'msg-list-chat-main display-2 cursor active'}
                                onClick={(() => {
                                    setShowd(row._id);
                                    getOneTicket(row._id);
                                    // setSelcMsg(row);
                                    setMdCHeck(true)
                                    msgSeen(row?._id);
                                    updateAgeById(row?._id);
                                })}>
                                <div className='display-1'>
                                    <div>
                                        <Avatar sx={{ width: 45, height: 45 }} alt="Remy Sharp" src={row?.user?.image} />
                                    </div>
                                    <div className='msg-list-chat-name' style={{ width: '100%' }}>
                                        {row.Title}


                                        <div className='chat-desc display-2' id='sentence'>
                                            {/* {(row.noftify?.length > 30) ? row.noftify.substring(0, 28) + '...' : row.noftify} */}
                                            {moment(row?.lastMsgTime).format('hh:mm:A')}
                                            {/* <span>4w</span> */}
                                        </div>

                                    </div>
                                </div>

                                {
                                    row?.msgcount > 0 &&
                                    <Badge badgeContent={row?.msgcount} color="primary">
                                    </Badge>

                                }
                            </div>
                        )
                    }) :
                    <div className='msg-list-chat-name' style={{ width: '100%', textAlign: 'center' }}>
                        No Data Found
                    </div>
                }

            </div>
        </div>
    )

    const chatBox = (
        <div className="colab-msg-box">
            {/* <div className="colab-avatar-cont vr">
            <Stack direction="row" spacing={1}>

                <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={chat[0].img} />

            </Stack>
        </div> */}
            <div className="p-10 display-1">
                <ArrowBackIcon onClick={(() => { setMdCHeck(false) })} />
                    <div>
                        Back to Chat
                    </div>
            </div>
            <div className="colab-chat-area msg">



                {selcMsg?.Message?.length > 0 &&
                    selcMsg?.Message?.map((todo, ind) => (

                        todo?.id == 'admin' ?

                            <div className='chat-area-inner-text vr' style={{ background: `${formatTime(todo?.time) === formatTime(time) && todo?.notification_id === location?.state?.notify ? '#13B799' : ''}` }}>
                                <div>
                                    <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
                                </div>
                                <div className="collab-send-text-main">
                                    {/* <div className="username">{perChat.name}(dev)</div> */}
                                    {/* <div className={`txt1 ${formatTime(todo?.time) === formatTime(time) && todo?.notification_id === location?.state?.notify ? 'msg-list-chat-main display-2 cursor active' : 'msg-list-chat-main display-2 cursor'}`}>{todo?.msg}</div> */}
                                    <div className='txt1'>{todo?.msg}</div>
                                    <div className="time">{moment(todo?.time).format('hh:mm:A')}</div>
                                </div>
                            </div> :
                            <div className="chat-area-inner-text user vr" key={ind}>
                                <div>
                                    <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={ticketData?.user?.image ? ticketData?.user?.image : perChat.imag} />
                                </div>
                                <div className="collab-send-text-main2 user">
                                    {/* <div className="username">Me(dev)</div> */}
                                    <div className='txt1'>{todo?.msg}</div>
                                    <div className="time">{moment(todo?.time).format('hh:mm:A')}</div>
                                </div>
                            </div>

                    ))}
            </div>


            <div className="colab-text-input">



                <div class="messageBox">
                    {ticketData?.Status == 'closed' ?
                        <div className='reason-txt'>
                            {`Your Ticket is Closed Reason:${ticketData.reason}`}
                        </div> :
                        <>
                            <input required="" placeholder="Message..." type="text" id="messageInput" value={emoji}
                                onChange={(e) => { handleChange(e) }}
                                onKeyDown={handleKeyDown}
                            />
                            <button id="sendButton" onClick={(e) => { handleSubmit(e) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
                                    <path
                                        fill="none"
                                        d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                                    ></path>
                                    <path
                                        stroke-linejoin="round"
                                        stroke-linecap="round"
                                        stroke-width="33.67"
                                        stroke="#6c6c6c"
                                        d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                                    ></path>
                                </svg>
                            </button>
                        </>
                    }
                </div>

            </div>
        </div >
    )

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                {/* <Header /> */}
                <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >

                    <Grid2 container spacing={2}>
                        {matches ? <Grid2 item size={{ xs: 12, sm: 12, md: 12, lg: 7.4, xl: 9 }}>
                            {chatBox}
                        </Grid2> : <>
                            <Grid2 item size={{ xs: 12, sm: 12, md: 12, lg: 7.4, xl: 9 }}>
                                {mdCHeck ? chatBox : chatContact}
                            </Grid2>
                        </>}

                        {matches && <Grid2 item size={{ xs: 12, sm: 12, md: 12, lg: 4.5, xl: 3 }} >

                            {chatContact}


                        </Grid2>
                        }
                    </Grid2>

                </Box>
                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="text-right">
                            <CancelOutlinedIcon sx={{ fill: 'var(--clr-font1)', cursor: 'pointer' }} onClick={handleClose} />
                        </div>
                        <div className="text-center">
                            New Ticket
                        </div>

                        <div className="text-center" style={{ marginTop: 'var(--mar-25px)' }}>
                            <TextField id="outlined-basic" placeholder='Enter Title' variant="outlined" className='prf-name tc'
                                value={title} onChange={(e) => { setTitle(e.target.value); setTitleErr(null) }} />
                        </div>
                        {titleErr && <p style={{ color: 'red' }}>{titleErr}</p>}
                        <div className="text-center" style={{ marginTop: 'var(--mar-25px)' }}>
                            <TextField
                                id="outlined-multiline-static"
                                variant="outlined" className='prf-name tc'
                                multiline
                                rows={4}
                                // defaultValue="Default Value"
                                value={msg}
                                onChange={(e) => { setMsg(e.target.value); setMsgErr(null) }}
                                placeholder='Enter Message'
                            />
                        </div>
                        {msgErr && <p style={{ color: 'red' }}>{msgErr}</p>}
                        <div className="tc-submit mt-20 text-center saveset x2">
                            {
                                load ? <Button variant='contained'>
                                    Processing...
                                </Button> :
                                    <Button variant='contained' onClick={() => createTicket()}>
                                        Submit
                                    </Button>
                            }

                        </div>

                    </Box>
                </Modal>
            </Box>
        </div>
    )
}

export default Support
