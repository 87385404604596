
import React, { useState, useEffect, useContext } from 'react';
import moon from "../../Images/moon.gif"
import sun from "../../Images/sun.gif"
import IconButton from '@mui/material/IconButton';
import { ThemeContext } from './ThemeProvider';



function DarkMode({ sendingModule }) {

    const { isLightMode, handleDarkmode } = useContext(ThemeContext);






    return (<>
        <IconButton sx={{ ml: 1 }} color="inherit" onClick={handleDarkmode}>
            {!isLightMode ? (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0C9.44771 0 9 0.447715 9 1V3C9 3.55228 9.44771 4 10 4C10.5523 4 11 3.55228 11 3V1C11 0.447715 10.5523 0 10 0Z" fill="#777E91" />
                    <path d="M10 16C9.44771 16 9 16.4477 9 17V19C9 19.5523 9.44771 20 10 20C10.5523 20 11 19.5523 11 19V17C11 16.4477 10.5523 16 10 16Z" fill="#777E91" />
                    <path d="M19 9C19.5523 9 20 9.44771 20 10C20 10.5523 19.5523 11 19 11H17C16.4477 11 16 10.5523 16 10C16 9.44771 16.4477 9 17 9H19Z" fill="#777E91" />
                    <path d="M4 10C4 9.44771 3.55228 9 3 9H1C0.447715 9 0 9.44771 0 10C-1.19209e-07 10.5523 0.447715 11 1 11H3C3.55228 11 4 10.5523 4 10Z" fill="#777E91" />
                    <path d="M15.6569 2.92903C16.0474 2.53851 16.6806 2.53851 17.0711 2.92903C17.4616 3.31955 17.4616 3.95272 17.0711 4.34324L15.6569 5.75746C15.2664 6.14798 14.6332 6.14798 14.2427 5.75746C13.8522 5.36693 13.8522 4.73377 14.2427 4.34324L15.6569 2.92903Z" fill="#777E91" />
                    <path d="M5.7574 14.2428C5.36688 13.8522 4.73371 13.8522 4.34319 14.2428L2.92897 15.657C2.53845 16.0475 2.53845 16.6807 2.92897 17.0712C3.3195 17.4617 3.95266 17.4617 4.34319 17.0712L5.7574 15.657C6.14792 15.2664 6.14792 14.6333 5.7574 14.2428Z" fill="#777E91" />
                    <path d="M17.0711 15.6569C17.4616 16.0474 17.4616 16.6806 17.0711 17.0711C16.6806 17.4616 16.0474 17.4616 15.6569 17.0711L14.2427 15.6569C13.8521 15.2664 13.8521 14.6332 14.2427 14.2427C14.6332 13.8522 15.2664 13.8522 15.6569 14.2427L17.0711 15.6569Z" fill="#777E91" />
                    <path d="M5.75737 5.7574C6.14789 5.36688 6.14789 4.73371 5.75737 4.34319L4.34316 2.92897C3.95263 2.53845 3.31947 2.53845 2.92894 2.92897C2.53842 3.3195 2.53842 3.95266 2.92894 4.34319L4.34316 5.7574C4.73368 6.14792 5.36685 6.14792 5.75737 5.7574Z" fill="#777E91" />
                </svg>

            ) : (
                <img src={moon} style={{ width: '40px', height: '40px' }} alt="moon" />
            )}
        </IconButton>
        {/* {isLightMode ? 'Light-mode' : 'Dark-Mode'} */}
    </>);
}


export default DarkMode;