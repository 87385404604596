import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from './Components/DarkMode/ThemeProvider';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'



const projectId = '8f93ce7670adfb8b924531d53d542cce'


const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}

// 3. Create a metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}


const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
  auth: {
    email: false,
    socials: [],

  }
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider>
    {/* <WagmiConfig config={wagmiConfig}> */}
    <App />
    {/* <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </WagmiConfig> */}
  </ThemeProvider>
);


